import React, { useEffect, useState } from "react";
import { Button, Card, Skeleton } from "antd";
import ReactLoading from "react-loading";

const WorkflowAddCalendarRecipients = (props: any) => {
  const [loading, setLoading] = useState(true);
  const [recipientEmails, setRecipientEmails] = useState<any>();
  const [calendarStatus, setCalendarStatus] = useState(false);
  let content: any;

  useEffect(() => {
    if (props.eventInfo.status === "confirmed") {
      setRecipientEmails(props.eventInfo.attendees);
      setCalendarStatus(true);
      setLoading(false);
    }
  }, []);

  if (loading) {
    content = (
      <Card bordered={false}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <span style={{ fontSize: 24 }}>Loading, please wait...</span>
          <ReactLoading type="spokes" color="#990000" />
        </div>
        <Skeleton active />
      </Card>
    );
  } else {
    if (calendarStatus) {
      const emails = recipientEmails.map((item: any, index: number) => {
        return <li key={index}>{item.email}</li>;
      });
      content = (
        <div>
          <Card title="Instructions:" style={{ marginBottom: "20px" }}>
            <div>
              Successfully Added Recipients to the Scheduled Calendar Events.
              Please find the list of participants who are invited.
              <br />
              <ul>{emails}</ul>
            </div>
          </Card>
          <div style={{ marginTop: 20 }}>
            <Button
              type="primary"
              onClick={() => {
                props.onCalendarAddEventsHanlder();
              }}
            >
              {props.currentStep?.nextStepTitle !== ""
                ? `${props.currentStep?.nextStepTitle.split('|')[0]}`
                : null}
            </Button>
          </div>
        </div>
      );
    } else {
      content = (
        <div>
          <Card title="Instructions:" style={{ marginBottom: "20px" }}>
            <div>
              There was some error in Adding recipients to your scheduled event.
              Please contact the administrator.
            </div>
          </Card>
          <div style={{ marginTop: 20 }}>
            <Button
              type="primary"
              onClick={() => {
                props.onCalendarAddEventsHanlder();
              }}
            >
              {props.currentStep?.nextStepTitle !== ""
                ? `${props.currentStep?.nextStepTitle.split('|')[0]}`
                : null}
            </Button>
          </div>
        </div>
      );
    }
  }
  return <>{content}</>;
};

export default WorkflowAddCalendarRecipients;
