import { useContext, useEffect, useState } from "react";
import { Link, Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";
import { Avatar, Button, Layout, message, Popover } from "antd";
import { getAuth, signOut } from "firebase/auth";
import { createUseStyles } from "react-jss";
import HamburgerMenu from "./Hamburger";
import sbulogo from "assets/SBU_Logo.png";
import { AuthContext } from "context/AuthProvider";
import apiCaller from "utils/apiCaller";
import AdminDashboardView from "views/AdminDashboardView";
import DashboardView from "views/DashboardView";
import StartWorkflowView from "views/StartWorkflowView";
import WorkflowView, { WorkflowFile } from "views/WorkflowView";
import AdminRoute from "components/AdminRoute";
import Impersonation from "components/impersonation/Impersonation";
import SideMenu from "components/SideMenu";
import Loader from "components/Loader";
import "../index.css";
const { Header, Content, Sider } = Layout;
const auth = getAuth();

const useStyles = createUseStyles({
  topPadding: {
    height: 48,
  },
  header: {
    background: "#990000",
    color: "#FFF",
    fontWeight: 600,
    fontSize: 20,
    display: "flex",
    position: "fixed",
    zIndex: 999,
    right: 0,
    width: "100%",
    height: 56,
    lineHeight: "48px",
    padding: "0 16px",
    alignItems: "center",
  },
  paper: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    background: "#990000",
    color: "#fff",
    marginTop: "50px",
    width: "100%",
    padding: 20,
  },
  imgBox: {
    width: "25%",
    padding: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#FFF",
    marginTop: "-20px",
    boxShadow: "0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%)",
  },
  button: {},
  options: {
    display: "flex",
    alignItems: "center",
  },
  option: {
    padding: "0 12px",
  },
});

const ApplicationPage = ({ match }: any) => {
  const classes = useStyles();
  const { currentUser, activeUser } = useContext(AuthContext);
  const location = useLocation();
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [redirected, setRedirected] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [visible, setVisible] = useState(false);
  const [allowedWorkflows, setAllowedWorkflows] = useState([]);

  useEffect(() => {
    fetchWorkflows();
  }, [activeUser]);

  //   TODO: Handle better logic for direct start
  useEffect(() => {
    const redirectURL = localStorage.getItem("redirectURL");
    if (redirectURL && !redirected) {
      startWorkflowDirectly(redirectURL);
    }
  }, [allowedWorkflows]);

  const fetchWorkflows = async () => {
    if (!activeUser) {
      setLoading(false);
      return;
    }

    try {
      const queryParams = new URLSearchParams(location.search);
      let includeWorkflow;
      if (queryParams.has("state")) {
        includeWorkflow = queryParams.get("state");
      }

      const allowedWorkflowTemplateData = await apiCaller.get("/api/workflow-templates/allowed", {
        params: { userId: activeUser?.id, userEmail: activeUser?.email, includeWorkflow: includeWorkflow },
      });

      if (allowedWorkflowTemplateData.data) {
        allowedWorkflowTemplateData.data.sort((a: any, b: any) => a.order - b.order);
        setAllowedWorkflows(allowedWorkflowTemplateData.data);
      }
    } catch (err) {
      console.error("Error in fetching the workflows: ", err);
    }

    setLoading(false);
  };

  const startWorkflowDirectly = (workflowName: string) => {
    const workflow:any = allowedWorkflows.find((workflow: any) => workflow?.name === workflowName);
    if (!workflow) {
      return;
    }
    if (workflow?.symLink){
      workflowName=workflow?.symLink
    }
    setRedirected(true);
    localStorage.removeItem("redirectURL");
    startNewWorkflow([workflowName]);
  };

  const startNewWorkflow = async (value: string[], selectedOption?: any[]) => {
    if (value.length === 0) return;

    const reqObj: any = {
      workflowName: value[0],
    };

    if (selectedOption && selectedOption[0].workflowType) {
      reqObj.workflowType = selectedOption[0].workflowType;
      reqObj.userEmail = activeUser.email;
    }

    try {
      const res = await apiCaller.post("/api/workflow", reqObj, {});
      history.push({
        pathname: `${match.path}/workflows/${res.data}`,
        state: { name: value[0] },
      });
    } catch (err) {
      message.error("Unauthorized");
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      message.success("Logged out successfully!");
      history.push("/");
    } catch (error) {
      message.error("There was some error in logging out. Please try again");
    }
  };

  if (loading) return <Loader />;

  return (
    <>
      <Layout style={{ minHeight: "100vh" }}>
        <Layout>
          {/* Left side menu bar */}
          {window.innerWidth > 767 ? (
            <div>
              <div style={{ width: "208px" }} />
              <Sider
                width={208}
                collapsedWidth={48}
                collapsible
                collapsed={collapsed}
                breakpoint="sm"
                className="siderMainClass"
                onCollapse={() => setCollapsed(!collapsed)}
                style={{
                  paddingTop: 56,
                  position: "fixed",
                  zIndex: 999,
                  height: "100%",
                }}
              >
                <SideMenu match={match} allowedWorkflows={allowedWorkflows} />
              </Sider>
            </div>
          ) : (
            <Sider
              width={208}
              collapsedWidth={48}
              collapsible
              collapsed={collapsed}
              breakpoint="sm"
              className="siderMainClass"
              onCollapse={() => setCollapsed(!collapsed)}
              style={{
                display: "none",
                paddingTop: 56,
                position: "fixed",
                zIndex: 999,
                height: "100%",
              }}
            >
              <SideMenu match={match} allowedWorkflows={allowedWorkflows} />
            </Sider>
          )}

          <Layout>
            <div className={classes.topPadding} />
            {/* Header Section */}
            <Header className={classes.header} style={{ justifyContent: "space-between" }}>
              <div className="logo-header-part">
                {window.innerWidth < 768 ? (
                  <div className="hamburger-menu">
                    <HamburgerMenu allowedWorkflows={allowedWorkflows}></HamburgerMenu>
                  </div>
                ) : (
                  <></>
                )}

                <div>
                  <img width="35" alt="SBU Logo" src={sbulogo} />
                  <Link to="/app/dashboard" style={{ paddingLeft: 10, color: "#FFF" }}>
                    CS Workflow
                  </Link>
                </div>
              </div>

              {/* <div style={{ flex: 1 }} /> */}

              {currentUser.id !== activeUser.id && (
                <div className="logo-header-part">
                  <span className="span-text">
                    Impersonating {activeUser.firstName} {activeUser.lastName}
                    {/* ({activeUser.email}) */}
                  </span>
                </div>
              )}
              <div className={classes.options}>
                <div className={`${classes.options} header-class-right`}>
                  <Impersonation />
                </div>
                <div className={`${classes.options} header-class-right`}>
                  <Popover
                    placement="bottomRight"
                    content={
                      <div>
                        <p>{currentUser?.displayName}</p>
                        <p>{currentUser?.email}</p>
                        <div>
                          <Button type="text" block onClick={handleLogout}>
                            Log Out
                          </Button>
                        </div>
                      </div>
                    }
                    trigger="click"
                    visible={visible}
                    onVisibleChange={() => setVisible(!visible)}
                  >
                    <Avatar src={`${currentUser?.photoURL as string}`} style={{ cursor: "pointer" }} />
                  </Popover>
                </div>
              </div>
            </Header>

            {/* Content Section based on URL path */}
            <Content style={{ margin: "24px 16px 0px" }}>
              <Switch>
                <AdminRoute path={`${match.path}/dashboard/admin`} component={AdminDashboardView} />
                <Route path={`${match.path}/dashboard`}>
                  <DashboardView />
                </Route>
                <Route path={`${match.path}/workflow/:section`}>
                  <StartWorkflowView allowedWorkflows={allowedWorkflows.filter((wf:WorkflowFile) => !wf.symLink)} onChange={startNewWorkflow} />
                </Route>
                <Route path={`${match.path}/workflows/:workflowId`}>
                  <WorkflowView allowedWorkflows={allowedWorkflows} />
                </Route>
                <Route path={`${match.path}/`}>
                  <Redirect to={`${match.path}/dashboard`} />
                </Route>
              </Switch>
            </Content>
          </Layout>
        </Layout>
      </Layout>
    </>
  );
};

export default ApplicationPage;
