import { Select } from "antd";

const MultiSelectSearch = ({ value, disabled, onChange, children }: any) => {
    return (
      <Select
      showSearch
      mode="multiple" //"tags" Allows adding new values by typing
      placeholder="Search to Select"
      optionFilterProp="label"
      defaultValue={value}
      disabled={disabled}
      filterOption={(input, option) => {
        // Ensure option.label is a string before calling toLowerCase
        const label = typeof option?.label === 'string' ? option.label.toLowerCase() : '';
        return label.includes(input.toLowerCase());
      }}
      filterSort={(optionA, optionB) => {
        // Ensure both labels are strings before comparing
        const labelA = typeof optionA.label === 'string' ? optionA.label.toLowerCase() : '';
        const labelB = typeof optionB.label === 'string' ? optionB.label.toLowerCase() : '';
        return labelA.localeCompare(labelB);
      }}
      onChange={onChange}
      options={children} // Using options instead of children
    />
  );
  };

  export default MultiSelectSearch;