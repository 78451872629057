import { useContext, useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Card, Checkbox, Col, Divider, Row, Skeleton, message } from "antd";
import { partition } from "lodash";

import apiCaller from "utils/apiCaller";
import { processWorkflowForTable } from "helpers";
import { DASHBOARD_FILTERS } from "models";
import { AuthContext } from "context/AuthProvider";
import WorkflowTable from "components/workflow/WorkflowTable";
import ReactLoading from 'react-loading';

const CheckboxGroup = Checkbox.Group;

/**
 * 'Workflows Started By You' - createdBy = activeUser
 * 'Workflows Assigned To You' - currentAssignee = activeUser || assignedTo = activeUser
 * 'Workflows You Participated In' - createdBy = activeUser || currentAssignee = activeUser || assignedTo = activeUser ||  workflow users.includes(activeUser)
 * 'Archived Workflows' - workflowArchived = true
 */
const CHECKBOX_OPTIONS = {
  STARTED: "Workflows Started By You",
  ASSIGNED: "Workflows Assigned To You",
  PARTICIPATED: "Workflows You Participated In",
  ARCHIVED: "Archived Workflows",
};

const FILTERS = {
  ASSIGNED: "Assigned Workflows",
  INCOMPLETE: "Workflows in Progress",
  ALL: "All Workflows",
};

const defaultCheckedList = [CHECKBOX_OPTIONS.STARTED, CHECKBOX_OPTIONS.ASSIGNED];

const incompleteWorkflowsCheckedList = [
  CHECKBOX_OPTIONS.STARTED,
  CHECKBOX_OPTIONS.ASSIGNED,
  CHECKBOX_OPTIONS.PARTICIPATED,
];

const allCheckedList = [
  CHECKBOX_OPTIONS.STARTED,
  CHECKBOX_OPTIONS.ASSIGNED,
  CHECKBOX_OPTIONS.PARTICIPATED,
  CHECKBOX_OPTIONS.ARCHIVED,
];

export const DASHBOARD_FILTERS_KEY = "DASHBOARD_FILTERS";
const INITIAL_FILTERS: DASHBOARD_FILTERS = { selectedfilter: "", checkedCheckboxes: [], pageNumber: 0, pageSize: 0 };

const DashboardView = () => {
  const { activeUser } = useContext(AuthContext);
  const location = useLocation();
  const history = useHistory();

  const [workflows, setWorkflows] = useState<any[]>([]);
  const [assignedWorkflows, setAssignedWorkflows] = useState<any[]>([]);
  const [incompleteWorkflows, setIncompleteWorkflows] = useState<any[]>([]);
  const [workflowsLoaded, setWorkflowsLoaded] = useState<boolean>(false);
  const [filter, setFilter] = useState<DASHBOARD_FILTERS>(INITIAL_FILTERS);
  const [reRunCalendarStep, setReRunCalendarStep] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [tableWorkflowData, setTableWorkflowData] = useState<any[]>([]);
  const [spinner, setSpinner] = useState(true);
  
  useEffect(() => {
    const fetchDataForSpinner = async () => {
      await new Promise((resolve) => setTimeout(resolve, 2000));
      setSpinner(false);
    };
    fetchDataForSpinner();
  }, []);

  useEffect(() => {
    authenticateCalendar();
  }, []);

  useEffect(() => {
    loadWorkflows();
  }, [activeUser]);

  useEffect(() => {
    if (workflowsLoaded) {
      loadDashboardFilters();
    }
  }, [workflowsLoaded]);

  useEffect(() => {
    if (!workflowsLoaded) return;

    let filteredWorkflows = workflows;

    if (filter.selectedfilter == FILTERS.ASSIGNED) {
      filteredWorkflows = assignedWorkflows;
    } else if (filter.selectedfilter == FILTERS.INCOMPLETE) {
      filteredWorkflows = incompleteWorkflows;
    }

    saveDashboardFilters();
    updateTableData(filteredWorkflows);
  }, [filter]);

  /**
   * @description: Authencticate calendar Step
   */
  const authenticateCalendar = async () => {
    try {
      const queryParams = new URLSearchParams(location.search);

      if (queryParams.has("code")) {
        const authorization_code = queryParams.get("code");
        const workflowState = queryParams.get("state");
        await apiCaller.post("/api/authenticate-calendar", {
          authCode: authorization_code,
        });
        if (workflowState) {
          history.push({
            pathname: `/app/workflows/${workflowState}`,
            state: { scheduleEvent: true },
          });
        }
      }
    } catch (err) {
      message.error("Calendar Authorization failed. Please contact Admin.");
    }
    setReRunCalendarStep(true);
  };

  /**
   * @description: Load the workflows
   */
  const loadWorkflows = async () => {
    setLoading(true);
    setWorkflowsLoaded(false);
    try {
      const workflowsResp = await apiCaller.get("/api/view-workflows", {
        params: {
          filter: "users",
        },
      });

      const workflowsData = workflowsResp.data;

      const uniqueEmailsFnd = new Set<String>();
      await workflowsData.forEach((workflow: any) => {
        uniqueEmailsFnd.add(workflow.createdBy);
        uniqueEmailsFnd.add(workflow.assignedTo);
        uniqueEmailsFnd.add(workflow.currentAssignee);
      });

      const usersResp = await apiCaller.post("/api/get-emails-against-users", {
        uniqueEmailsFnd: Array.from(uniqueEmailsFnd),
      });

      const users = usersResp.data;

      const workflows = workflowsData.map((workflow: any) => processWorkflowForTable(workflow, users));

      const assignedWorkflowsMap = new Map();

      workflows.forEach((workflow: any) => {
        if (workflow.currentAssignee == activeUser.email || workflow.assignedTo == activeUser.email) {
          assignedWorkflowsMap.set(workflow.id, workflow);
        }
      });

      const assignedWF = Array.from(assignedWorkflowsMap.values());
      const [completedWF, incompleteWF] = partition(workflows, ["workflowComplete", true]);

      setAssignedWorkflows(assignedWF);
      setIncompleteWorkflows(incompleteWF);
      setWorkflows(workflows);
    } catch (err) {
      console.error("Error in Loading workflows: ", err);
    }
    setLoading(false);
    setWorkflowsLoaded(true);
  };

  /**
   * @description: Load the saved dashboard filter from local storage
   */
  const loadDashboardFilters = () => {
    const savedFiltersStr = localStorage.getItem(DASHBOARD_FILTERS_KEY);

    if (savedFiltersStr) {
      const savedFilters: DASHBOARD_FILTERS = JSON.parse(savedFiltersStr);
      setFilter({ ...savedFilters });
    } else {
      setFilter({ ...filter, selectedfilter: FILTERS.INCOMPLETE, checkedCheckboxes: defaultCheckedList });
    }
  };

  /**
   * @description: Save the selected dashboard filters to local storage
   */
  const saveDashboardFilters = () => {
    localStorage.setItem(DASHBOARD_FILTERS_KEY, JSON.stringify(filter));
  };

  /**
   * @description: Sort workflowsList based on "lastModified" time (if present in WF object) , else use "createdAt"
   * @param workflowList: Filtered WF list
   */
  const sortWorkflows = (workflowList: Array<any>) => {
    workflowList.sort((a, b) =>
      "lastModified" in a && "lastModified" in b
        ? b.lastModified?._seconds - a.lastModified?._seconds
        : b.createdAt._seconds - a.createdAt._seconds
    );

    return workflowList;
  };

  /**
   * @description: Filter the workflow list on based of applied checkboxes
   * @param workflows: Selected Workflows
   */
  const getFilteredWorkflows = (workflows: any[]) => {
    const filteredWorkflowData = [];
    const { checkedCheckboxes } = filter;

    if (checkedCheckboxes?.includes(CHECKBOX_OPTIONS.ASSIGNED)) {
      for (const workflow of workflows) {
        if (
          (workflow?.currentAssignee === activeUser?.email || workflow?.assignedTo === activeUser?.email) &&
          !workflow.workflowArchived
        ) {
          filteredWorkflowData.push(workflow);
        }
      }
    }
    if (checkedCheckboxes?.includes(CHECKBOX_OPTIONS.STARTED)) {
      for (const workflow of workflows) {
        if (workflow?.createdBy === activeUser.email && !workflow.workflowArchived) {
          filteredWorkflowData.push(workflow);
        }
      }
    }
    if (checkedCheckboxes?.includes(CHECKBOX_OPTIONS.PARTICIPATED)) {
      for (const workflow of workflows) {
        if (
          (workflow?.createdBy === activeUser.email ||
            workflow?.currentAssignee === activeUser?.email ||
            workflow?.assignedTo === activeUser?.email ||
            workflow?.users.includes(activeUser?.email)) &&
          !workflow?.workflowArchived
        ) {
          filteredWorkflowData.push(workflow);
        }
      }
    }
    if (checkedCheckboxes?.includes(CHECKBOX_OPTIONS.ARCHIVED)) {
      for (const workflow of workflows) {
        if (workflow.workflowArchived) {
          filteredWorkflowData.push(workflow);
        }
      }
    }

    return Array.from(new Set(filteredWorkflowData));
  };

  /**
   * @description: Update the table workflow data
   * @param workflows: Selected workflows
   */
  const updateTableData = (workflows: any[]) => {
    const filteredWorkflows = getFilteredWorkflows(workflows);
    const sortedWorkflows = sortWorkflows(filteredWorkflows);
    setTableWorkflowData(sortedWorkflows);
  };

  /**
   * @description: Filter workflows card click handler
   * @param selectedFilter: Selected Filter
   */
  const filterWorkflowsClickHandler = (selectedFilter: string) => {
    let checkedList = defaultCheckedList;

    if (selectedFilter == FILTERS.ASSIGNED) {
      checkedList = defaultCheckedList;
    } else if (selectedFilter == FILTERS.INCOMPLETE) {
      checkedList = incompleteWorkflowsCheckedList;
    } else if (selectedFilter == FILTERS.ALL) {
      checkedList = allCheckedList;
    }

    setFilter({ ...filter, selectedfilter: selectedFilter, checkedCheckboxes: checkedList });
  };

  /**
   * @description: Checkbox Click Handler
   * @param checkedCheckboxesList: Checked checkbox list
   */
  const checkboxClickHandler = (checkedCheckboxesList: any) => {
    setFilter({ ...filter, checkedCheckboxes: checkedCheckboxesList });
  };

  /**
   * @description: Info card for workflow filters
   */
  const Info = ({ title, value, onClick }: any) => (
    <div style={{ position: "relative", textAlign: "center", cursor: "pointer" }} onClick={() => onClick()}>
      <span
        style={{
          width: "200px",
          padding: "10px",
          color: filter.selectedfilter == title ? "#1890ff" : "rgba(0,0,0,0.45)",
          display: "inline-block",
          fontSize: "16px",
          lineHeight: "22px",
        }}
      >
        {title}
        <p
          style={{
            margin: 0,
            fontSize: "24px",
            lineHeight: "32px",
            color: filter.selectedfilter == title ? "#1890ff" : "black",
          }}
        >
          {value}
        </p>
      </span>
    </div>
  );

  return (
    <div> {spinner ? (
      <div className="spinner">
          <h1>Loading...</h1>
          <ReactLoading type='spinningBubbles' color='#990000' />
      </div>
    ) : (
    <div>
      {/* Top Info Bar */}
      <Card>
        <Row>
          <Col sm={8} xs={24}>
            <Info
              title={FILTERS.ASSIGNED}
              value={assignedWorkflows.length}
              onClick={() => filterWorkflowsClickHandler(FILTERS.ASSIGNED)}
            />
          </Col>
          <Col sm={8} xs={24}>
            <Info
              title={FILTERS.INCOMPLETE}
              value={incompleteWorkflows.length}
              onClick={() => filterWorkflowsClickHandler(FILTERS.INCOMPLETE)}
            />
          </Col>
          <Col sm={8} xs={24}>
            <Info
              title={FILTERS.ALL}
              value={workflows.length}
              onClick={() => filterWorkflowsClickHandler(FILTERS.ALL)}
            />
          </Col>
        </Row>
      </Card>
      {/* Table of all workflows of the current user */}
      <Card bordered={false} style={{ marginTop: "24px" }}>
        <>
          <CheckboxGroup options={allCheckedList} value={filter.checkedCheckboxes} onChange={checkboxClickHandler} />

          <Divider />
          {!loading ? <WorkflowTable data={tableWorkflowData} reRunStep={reRunCalendarStep} /> : <Skeleton active />}
        </>
      </Card>
    </div>)}
    </div>
  );
};

export default DashboardView;

