import { useState } from "react";

import "./PdfPreviewModal.css";
import PDFPreviewGenerate from "./PDFPreviewGenerate";

interface Props {
  workflowFormKey: any;
}

function PdfPreviewModal({ workflowFormKey }: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [generating, setGenerating] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return <PDFPreviewGenerate formName={workflowFormKey} setGenerating={setGenerating} />;
}

export default PdfPreviewModal;
