import { getStepsOrder } from "./getStepsOrder";

export const processWorkflowForTable = (workflow: any, userMap: any) => {
  const assignee = workflow.assignedTo || workflow.currentAssignee;

  return {
    key: workflow.id,
    assignee: assignee,
    createdByEmailName: [workflow.createdBy, userMap[workflow.createdBy]],
    assigneeEmailName: [assignee, userMap[assignee]],
    workflowSteps: getStepsOrder(workflow.steps),
    ...workflow,
  };
};
