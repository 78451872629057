import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { createUseStyles } from "react-jss";
import { FaGoogle } from "react-icons/fa";
import { Button, message, Form, Input, Modal, Select, Space } from "antd";
import { getAuth, GoogleAuthProvider, signInWithPopup, signOut } from "firebase/auth";

import apiCaller from "utils/apiCaller";
import sbulogo from "assets/SBU_Logo.png";

const auth = getAuth();
const useStyles = createUseStyles({
  paper: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    background: "#990000",
    color: "#fff",
    marginTop: "50px",
    width: "100%",
  },
  imgBox: {
    width: "25%",
    padding: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#FFF",
    marginTop: "-20px",
    boxShadow: "0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%)",
  },
});

const LoginPage = (props: any) => {
  const classes = useStyles();
  const history = useHistory();
  const [showUserSignUpForm, setShowUserSignUpForm] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        history.push("app/dashboard");
      }
    });

    unsubscribe();
  }, []);

  const handleSignIn = async () => {
    const googleAuthProvider = new GoogleAuthProvider();

    signInWithPopup(auth, googleAuthProvider)
      .then(async (result) => {
        const { providerId, user } = result;

        if (providerId !== "google.com" || !user.email?.includes("stonybrook.edu")) {
          await signOut(auth);
          message.error("Allowed account domains are `cs.stonybrook.edu` and `stonybrook.edu` only, please try again.");
          return;
        }

        try {
          const userExists = await apiCaller.get("/api/user", {
            params: {
              email: user.email,
            },
          });

          // first time login with @cs then create new user in DB
          if (userExists.data == null) {
            await apiCaller.post("/api/user", { uid: user?.uid, name: user?.displayName, email: user?.email }, {});
          }

          history.push(props?.redirectURL || "/app/dashboard");
        } catch (error: any) {
          if (error.response.status === 403) {
            setShowUserSignUpForm(true);
          } else {
            message.error("There was some error logging you in. Please try again.");
          }
          await signOut(auth);
        }
      })
      .catch((error) => {
        console.error("Error in signing in: ", error);
      });
  };

  const handleHelp = () => {
    const helpText = "Please contact `CS IT department` if you are having trouble with your @cs account.";
    message.success(helpText);
  };

  const handleNewUserRequest = async (values: any) => {
    try {
      const payload = {
        ...values,
        replyTo: values.sbuEmail,
      };
      await apiCaller.post("/api/user/new-request", payload, {});
      message.success("Your request has been submitted. You will be notified once your account is created.");
      setShowUserSignUpForm(false);
    } catch (error: any) {
      message.error("There was some error submitting your request. Please try again.");
    }
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
    },
  };

  return (
    <div className={classes.paper}>
      <div className={classes.imgBox}>
        <img width="75%" alt="SBU Logo" src={sbulogo} />
      </div>

      <h1 style={{ color: "#fff", marginTop: "15px" }}>CS Workflow</h1>
      <Button
        onClick={handleSignIn}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <span style={{ marginRight: "10px" }}>Sign in with Google</span> <FaGoogle />
      </Button>
      <Button
        onClick={handleHelp}
        style={{
          backgroundColor: "#990000",
          display: "inline-block",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          border: "none",
        }}
      >
        <span style={{ color: "#fff", marginTop: "10px" }}>Not able to sign in?</span>{" "}
      </Button>
      <span style={{ marginTop: "50px", padding: "10px" }}>Copyright © CS Workflow {new Date().getFullYear()}</span>

      <Modal
        style={{ top: 40 }}
        width={800}
        visible={showUserSignUpForm}
        onOk={() => setShowUserSignUpForm(false)}
        onCancel={() => setShowUserSignUpForm(false)}
        footer={null}
      >
        <Space direction="horizontal" style={{ margin: "20px", width: "100%", justifyContent: "center" }}>
          <h2>
            Your account does not currently exist in our system. Please fill out the following form to request an
            account
          </h2>
        </Space>

        <Form
          {...layout}
          name="nest-messages"
          onFinish={handleNewUserRequest}
          style={{ maxWidth: 600 }}
          validateMessages={validateMessages}
        >
          <Form.Item name={["firstName"]} label="First Name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name={["lastName"]} label="Last Name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name={["csEmail"]} label="CS Email" rules={[{ type: "email" }]}>
            <Input />
          </Form.Item>
          <Form.Item name={["sbuEmail"]} label="SBU Email" rules={[{ type: "email", required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name={["netId"]} label="NetID" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name={["sbuId"]} label="SBU ID">
            <Input />
          </Form.Item>
          <Form.Item name={["major"]} label="Major">
            <Input />
          </Form.Item>
          <Form.Item name={["reason_for_access"]} label="Reason For Access">
            <Input />
          </Form.Item>
          <Form.Item name={["role"]} label="Role" rules={[{ required: true }]}>
            <Select
              defaultValue=""
              style={{ width: 200 }}          
              options={[
                { value: "Student", label: "Student" },
                { value: "Faculty", label: "Faculty" },
                { value: "Staff", label: "Staff" },
              ]}
            />
          </Form.Item>
          <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.role !== currentValues.role}>
            {({ getFieldValue }) => {
              return getFieldValue("role") === "Student" ? (
                <Form.Item name={["level"]} label="Level">
                  <Select
                    defaultValue=""
                    style={{ width: 200 }}
                    options={[
                      { value: "U1", label: "U1" },
                      { value: "U2", label: "U2" },
                      { value: "U3", label: "U3" },
                      { value: "U4", label: "U4" },
                      { value: "G1", label: "G1" },
                      { value: "G2", label: "G2" },
                      { value: "G3", label: "G3" },
                      { value: "G4", label: "G4" },
                      { value: "G5", label: "G5" },
                    ]}
                  />
                </Form.Item>
              ) : null;
            }}
          </Form.Item>
          <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default LoginPage;
