import { useCallback, useContext, useEffect, useState, useRef } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Button, Card, Divider, Input, Modal, Result, Skeleton, message } from "antd";

import { AuthContext } from "context/AuthProvider";
import apiCaller from "utils/apiCaller";
import { Workflow } from "models/Workflow";
import { getStepsOrder } from "helpers";
import WFForm from "components/workflow/WFForm";
import WorkflowAssign from "components/workflow/WorkflowAssign";
import DottyDisplay from "components/workflow/DottyDisplay";
import OutForSignatures from "components/workflow/OutForSignatures";
import PdfPreviewModal from "components/workflow/PdfPreviewModal";
import ResourceTable from "components/workflow/ResourceTable";
import Stepper from "components/workflow/Stepper";
import WorkflowCSV from "components/workflow/WorkflowCSV";
import WorkflowCSVReport from "components/workflow/WorkflowCSVReport";
import WorkflowGenerate from "components/workflow/WorkflowGenerate";
import WorkflowMultiform from "components/workflow/WorkflowMultiform";
import WorkflowReport from "components/workflow/WorkflowReport";
import WorkflowSelect from "components/workflow/WorkflowSelect";
import WorkflowUpdate from "components/workflow/WorkflowUpdate";
import WorkflowComment from "components/workflow/WorkflowComment";
import WorkflowChecklist from "components/workflow/WorkflowChecklist";
import WorkflowInstructionBox from "components/workflow/WorkflowInstructionBox";
import WorkflowInstruction from "components/workflow/WorkflowInstruction";
import WorkflowSendCalendarInvites from "components/workflow/WorkflowSendCalendarInvites";
import WorkflowAddCalendarRecipients from "components/workflow/WorkflowAddCalendarRecipients";
import WorkflowMailTo from "components/workflow/WorkflowMailTo";
import Aggregate from "components/workflow/Aggregate";
import WorkflowScheduleHold from "components/workflow/WorkflowScheduleHold";
import Loader from "components/Loader";
import HelpModal from "components/workflow/HelpModal";

export interface WorkflowFile {
  formKey: string;
  name: string;
  order: number;
  roles: string[];
  section: string;
  showWorkflowSteps: string;
  steps: any;
  workflowType: string;
  symLink?:string;
}

type Function = "FORM" | "ASSIGN" | "TABLE" | "GENERATE" | "CHECK" | "SIGNATURE" | "PRINT" | "CHECKLIST" | "FINISH";

interface Params {
  workflowId: string;
}

interface State {
  name: string;
  workflowFileName: any;
  workflowSteps: any;
}

interface Props {
  allowedWorkflows: WorkflowFile[];
}

interface Next {
  yes: string;
  no: string;
}

interface Step {
  title: string;
  instructions: string;
  description: string;
  function: Function;
  checkListForm?: any;
  params?: any;
  next: string | Next;
  prev?: string;
  nextStepTitle?: string;
  acceptStep?: string;
  reassignStep?: string;
  steps?: any;
  reassignEmail?: string;
  rejectStep?: string;
  prevStepTitle?: string;
}

const WorkflowView = ({ allowedWorkflows }: Props) => {
  const { workflowId } = useParams<Params>(); // name contains the name of the workflow e.g. gradSchool
  const location = useLocation<State>(); // state.form contains the type of form we would use
  const state = location.state;
  const history = useHistory();
  const [isMobile, setIsMobile] = useState(false);
  const { activeUser } = useContext(AuthContext);
  const [showSidePanel, setShowSidePanel] = useState(false); // State to control the visibility of the side panel
  const [workflow, setWorkflow] = useState<Workflow>();
  const [loading, setLoading] = useState(true);
  const [currentStep, setCurrentStep] = useState<Step>();
  const [currentStepName, setCurrentStepName] = useState("");
  const [generating, setGenerating] = useState(false);
  const [complete, setComplete] = useState({
    isComplete: false,
    title: "",
    subtitle: "",
  });
  const [selectedUser, setSelectedUser] = useState();
  const [assignedUser, setAssignedUser] = useState("");
  const [commentModalVisible, setCommentModalVisible] = useState(false);
  const [rejectModalVisible, setRejectModalVisible] = useState(false);
  const [helpModalVisible, setHelpModalVisible] = useState(false);
  const [comment, setComment] = useState("");
  const [helpMessage, setHelpMessage] = useState("");
  const [workflowKey, setWorfklowKey] = useState("");
  const [workflowName, setWorkflowName] = useState("");
  const [workflowSteps, setWorkflowSteps] = useState<any>({});
  const [checkListData, setCheckListData] = useState<any>([]);
  const [wfUsers, setWfUsers] = useState<any>({});
  const [actionsWtActor, setActions] = useState<any>({});
  const [workflowType, setWorkflowType] = useState("");
  const [rejectionComment, setRejectionComment] = useState("");
  const [allStepsInfo,setAllStepsInfo]=useState<any>({});
  const durationInSeconds = 2;
  const [users,setUsers]=useState<any[]>();

  // UseEffect to set the value in the rejection Text box to currentStep?.params.defaultRejectionComment( the default value for the rejection email )
  useEffect(() => {
    if (rejectModalVisible) {
      setRejectionComment(currentStep?.params.defaultRejectionComment || "");
    }
  }, [rejectModalVisible]);

  useEffect(() => {
    if (window.innerWidth < 991) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [window.innerWidth]);

  const getLogsWithActorInfo = useCallback(async () => {
    const logs = await apiCaller.get("/api/workflow-log", {
      params: { workflowId: workflowId },
    });
    let allLogs: any[] = logs.data;
    for (let i = allLogs.length - 1; i >= 0; i--) {
      let log = allLogs[i];
      if (log["type"] === "NEXT") {
        let desc: string[] = log["description"].split(" ");
        actionsWtActor[desc[desc.length - 1]] = {
          actor: log["user"],
          timestamp: log["timestamp"],
        };
      } else if (log["type"] === "CREATE") {
        actionsWtActor["createTimestamp"] = log["timestamp"];
      }
    }
    setActions(actionsWtActor);
  }, [actionsWtActor, workflowId]);

  // Get all workflow steps and logs of the current workflow using workflow id
  useEffect(() => {
    getLogsWithActorInfo();
  }, [getLogsWithActorInfo]);

  // Load the actual workflow new/existing
  useEffect(() => {
    loadWorkflowData();
  }, [history, state, workflowId, allowedWorkflows, workflowType]);

  const getWorkflowData = async () => {
    const workflowResp = await apiCaller.get("/api/workflow", {
      params: { id: workflowId },
      timeout: 30000,
    });
    const workflow = workflowResp?.data;
    return workflow;
  };

  const loadWorkflowData = async () => {
    try {
      if (state && state.name) {
        await loadNewWorkflow();
      } else {
        const workflow = await getWorkflowData();
        if (workflow.assignedTo === activeUser?.email) {
          const requestData = { workflowId, currentAssignee: activeUser?.email };
          await apiCaller.post("/api/accept-workflow", requestData, {});
        }
        await loadExistingWorkflow();
      }
      setLoading(false);
    } catch (err) {
      console.error("Error in loading the workflow data: ", err);
    }
  };

  const loadNewWorkflow = async () => {
    try {
      const newWorkflow = allowedWorkflows.find((workflow: any) => workflow.name === state.name);
      const firstStepAfterBegin = newWorkflow?.steps[newWorkflow?.steps.begin];

      setWorkflowSteps(getStepsOrder(newWorkflow?.steps));
      setAllStepsInfo(newWorkflow?.steps);
      if (newWorkflow?.workflowType === "single-instance") {
        setWorkflowType("single-instance");
      }

      if (firstStepAfterBegin.function === "CHECKLIST") {
        const checkListSteps = firstStepAfterBegin.steps.map((item: any) => {
          return { step: item.step, name: item.title, done: false };
        });
        setCheckListData(checkListSteps);
      }

      setWorkflowName(newWorkflow?.name ?? "");
      setWorfklowKey(newWorkflow?.formKey ?? "");

      const usersResp = await apiCaller.post("/api/get-emails-against-users", {
        uniqueEmailsFnd: undefined,
      });
      setWfUsers(usersResp.data);

      let nextStepTitle = "";
      // Custom Next Title is unused as of 1st June, 2023
      // TODO: [Deprecate customNextTitle]  -acting on deprecating by changing it from .customNextTitle -> .title
      const customNextTitle = newWorkflow?.steps[newWorkflow?.steps.begin].customNextTitle;
      if (customNextTitle && typeof customNextTitle === "string") {
        nextStepTitle = customNextTitle;
      } else {
        const nextStepName = newWorkflow?.steps[newWorkflow?.steps.begin].next;
        if (typeof nextStepName === "string" && nextStepName.length !== 0) {
          nextStepTitle = `Next (${(newWorkflow?.steps[nextStepName].title.split('|')[0]).replace(/To/g, "to")})`;
        }
      }
      setCurrentStep({
        ...newWorkflow?.steps[newWorkflow?.steps.begin],
        nextStepTitle,
      });
      setCurrentStepName(newWorkflow?.steps.begin);
    } catch (err) {
      console.error("Error in loading a new worklow: ", err);
    }
  };

  const loadExistingWorkflow = async () => {
    try {
      const workflow = await getWorkflowData();
      if (!workflow) throw new Error("Workflow not found!");

      const existingWorkflow = allowedWorkflows.find((wF: any) => wF.name === workflow.workflowFile);
      if (!existingWorkflow) throw new Error("You don't have access to this workflow");

      setWorkflow(workflow);
      setCurrentStep(workflow.currentStepObj);
      setCurrentStepName(workflow.currentStep);
      setWorkflowName(workflow.workflowFile);
      setWorkflowSteps(getStepsOrder(workflow.steps));
      setAllStepsInfo(workflow.steps);

      // Check if the workflow is a single-instance workflow
      if (existingWorkflow?.workflowType === "single-instance") {
        setWorkflowType("single-instance");
      }

      const firstStep = workflow.steps[workflow.steps.begin];

      if (firstStep.function === "CHECKLIST") {
        const checkListSteps = firstStep.steps.map((item: any) => {
          return { step: item.step, name: item.title, done: false };
        });

        if (checkListSteps.length !== 0) {
          const updatedCheckList = updateDoneStatus(checkListSteps, workflow.currentStep);
          setCheckListData(updatedCheckList);
        }
      }

      const usersResp = await apiCaller.post(
        "/api/get-emails-against-users",
        {
          uniqueEmailsFnd: Array.from(workflow.users),
        },
        { timeout: 500000 }
      );

      setWfUsers(usersResp.data);

      if (workflow.assignedTo !== "") {
        if (workflow.currentStepObj.function === "BUFFER") {
          setComplete({
            isComplete: true,
            title: "Successfully Assigned Workflow!",
            subtitle: "The next assignee will continue the workflow from here.",
          });
        } else {
          setComplete({
            isComplete: true,
            title: "Successfully Assigned Workflow!",
            subtitle: `The workflow was either retracted or submitted. You can still go back and make changes if the workflow hasn't been accepted by ${workflow.assignedTo} yet.`,
          });
        }
      }
    } catch (e: any) {
      const errorStr = e.message || "Workflow not found!";

      // await apiCaller.post("/api/workflow-error-alert", {
      //   name: activeUser?.name,
      //   email: activeUser?.email,
      //   workflowId: workflowId,
      //   workflowName: workflow?.workflowFile ?? workflowName,
      //   errorMessage: errorStr,
      // });

      message.error(errorStr);
      history.push("/app");
    }
  };

  const handlePrevStep = async () => {
    setLoading(true);
    try {
      const res = await apiCaller.post("/api/prev-step", { workflowId });
      const workflow = res.data;

      if (workflow) {
        setWorkflow(workflow);
        setCurrentStep(workflow.currentStepObj);
        setCurrentStepName(workflow.currentStep);
      }
    } catch (err) {
      console.error("Error in handling the prev step: ", err);
    }
    setLoading(false);
  };

  const handleNextStep = async (values?: any, choice?: string, authCode?: string) => {
    setLoading(true);
    try {
      let formValues: any = {};
      if ((currentStep?.function === "FORM" || currentStep?.function === "CHECK") && values) {
        formValues = {
          ...values,
          step: currentStep.params.type === "update_form" ? currentStep.params.formStep : currentStepName,
        };
        if (formValues.roles) {
          await apiCaller.post("/api/change-admin-roles", {
            email: formValues.email,
            roles: formValues.roles,
          });
        }
        if (formValues.impRoles) {
          await apiCaller.post("/api/change-imp-roles", {
            email: formValues.email,
            impRoles: formValues.impRoles,
          });
        }
      }

      const res = await apiCaller.post("/api/next-step", {
        workflowId,
        formValues,
        choice,
        values,
        assignedUser,
        authCode: authCode,
        selectedUser: selectedUser,
        workflowName: !workflow ? (state && state.name ? state.name : "") : "", // only relevant for new workflows
      });
      setWorkflow(res.data);
      if (checkListData) {
        const updatedCheckList = updateDoneStatus(checkListData, res.data.currentStep);
        setCheckListData(updatedCheckList);
      }
      setCurrentStep(res.data.currentStepObj);
      if (res.data.hasAssigned) {
        if (res.data.currentStepObj?.params.assignee) {
          setComplete({
            isComplete: true,
            title: "Successfully Assigned Workflow!",
            subtitle: `You can still go back and make changes if the workflow hasn't been accepted by ${res.data.currentStepObj?.params.assignee} yet.`,
          });
        } else {
          setComplete({
            isComplete: true,
            title: "Successfully Assigned Workflow!",
            subtitle: "The next assignee will continue the workflow from here.",
          });
        }
      } else if (res.data.hasSentForSignatures) {
        setComplete({
          isComplete: true,
          title: "Out For Signatures",
          subtitle: `The form associated with this workflow is currently out for signatures from ${currentStep?.params.emails.join(
            ", "
          )}`,
        });
      } else {
        if (state && state.name) {
          history.replace({ ...location, state: undefined });
        }
      }
      if (res.data.currentStep) {
        setCurrentStepName(res.data.currentStep);
      }
    } catch (err) {
      console.error("Error in handling the next step: ", err);
    }
    setLoading(false);
  };

  useEffect(()=>{
    async function getUserData() {
    const usersData = await apiCaller.get("/api/users");

    const users = usersData.data.map((user: any) => ({
      key: user.id,
      ...user,
    }));
    setUsers(users);
  }
  getUserData();
  },[])

  const handleCSVUpload = async (data: any) => {
    setLoading(true);
    const formData = workflow?.formData;
    const type = currentStep?.params.type;
    const target = currentStep?.params.target;
    let listName = target ?? "";
    if (formData && formData.formValues && formData.formValues.userList) {
      listName = formData.formValues.userList;
    }
    const res = await apiCaller.post(
      "/api/csv",
      {
        workflowId,
        data,
        type,
        target: listName,
        workflowName: !workflow ? state.name : "",
      },
      { timeout: 500000 }
    );
    setWorkflow(res.data);
    setCurrentStep(res.data.currentStepObj);
    setCurrentStepName(res.data.currentStep);
    setLoading(false);
    message.success("Uploaded CSV successfully!", durationInSeconds);
  };

  const archiveWorkflow = async () => {
    await apiCaller.post("/api/archive-workflow", { workflowId });
    history.push("/app/dashboard");
  };

  const unarchiveWorkflow = async () => {
    await apiCaller.post("/api/unarchive-workflow", { workflowId });
    history.push("/app/dashboard");
  };

  const openHelpModal = () => {
    setHelpModalVisible(true);
  };

  const closeHelpModal = () => {
    setHelpModalVisible(false);
    // setHelpMessage("");
  };

  const handleDataFromHelpModal = (data: any) => {
    sendHelpRequest(data);
  };
  const sendHelpRequest = async (helpMessage: string) => {
    if (helpMessage.trim() == "") {
      return;
    }
    await apiCaller.post("/api/send-help-request", {
      helpMessage,
      workflowId,
      workflowName: workflow?.workflowFile ?? workflowName,
    });
    closeHelpModal();
    message.success("Your help request has been sent!", durationInSeconds);
    // closeHelpModal();
  };

  const PrevButton = ({ isAssign}: { isAssign?: boolean }) => (
    <>
      {currentStep?.prev ? (
        <Button
          style={{marginBottom: "5px"}}
          onClick={async () => {
            if (isAssign) {
              await apiCaller.post("/api/remove-workflow-assignee", {
                workflowId,
              });
            }
            await handlePrevStep();
            setComplete({
              isComplete: false,
              title: "",
              subtitle: "",
            });
          }}
        >
          Return to {currentStep.prevStepTitle ? currentStep.prevStepTitle.split('|')[0] : "Previous Step"}
        </Button>
      ) : null}
    </>
  );

  const updateDoneStatus = (array: any, targetStep: any) => {
    let targetStepIndex = array.findIndex((obj: any) => obj["step"] === targetStep);

    for (let i = 0; i < array.length; i++) {
      if (i < targetStepIndex || targetStepIndex === -1) {
        array[i].done = true;
      } else {
        array[i].done = false;
      }
    }
    return array;
  };

  const handleDirectLinkClick = async () => {
    try {
      await navigator.clipboard.writeText(
        `https://workflow.cs.stonybrook.edu/app/dashboard/direct-start/${encodeURIComponent(workflowName)}`
      );
      message.success("Direct Link copied to clipboard", durationInSeconds);
    } catch (err) {
      message.success("There is some error in copying the direct link to clipboard", durationInSeconds);
    }
  };
  let check_step_reject_string="Reject"
  let check_step_accept_string="Proceed to "+currentStep?.acceptStep
  if (workflow?.currentStep==="confirm_keycard_access"){
    check_step_reject_string="Access Not Working"
    check_step_accept_string="Confirm Access"
  }
  const renderStep = () => ({
    SCHEDULED_HOLD: (
      <div className="workflow-step">
        <WorkflowScheduleHold
          currentStep={currentStep}
          workflow={workflow}
          workflowId={workflowId}
          prevButton={<PrevButton />}
          onScheduleHoldComplete={() => handleNextStep()}
        />
      </div>
    ),
    AGGREGATE: (
      <div className="workflow-step">
        <Aggregate formKey={workflow?.formKey || workflowKey} />
      </div>
    ),
    CHECKLIST: (
      <div className="workflow-step">
        <WorkflowChecklist
          checkList={checkListData}
          currentStep={currentStep}
          onWorkflowChecklistNext={() => handleNextStep()}
          onWorkflowChecklistClick={() => handleNextStep()}
        />
      </div>
    ),
    MAILTO: (
      <div className="workflow-step">
        <WorkflowMailTo
          currentStep={currentStep}
          workflow={workflow}
          workflowId={workflowId}
          prevButton={<PrevButton />}
          onWorkflowMailToComplete={() => handleNextStep()}
        />
      </div>
    ),
    CALENDAR_INVITES: (
      <div className="workflow-step">
        <WorkflowSendCalendarInvites
          workflow={workflow}
          workflowId={workflowId}
          currentStep={currentStep}
          prevButton={<PrevButton />}
          onCalendarInvitesHandler={() => handleNextStep()}
        />
      </div>
    ),
    ADD_EVENT_RECEIPIENTS: (
      <div className="workflow-step">
        <WorkflowAddCalendarRecipients
          currentStep={currentStep}
          eventInfo={currentStep?.params.calendarEvent}
          onCalendarAddEventsHanlder={() => handleNextStep()}
        />
      </div>
    ),
    INSTRUCTIONS: (
      <div className="workflow-step">
        <WorkflowInstruction
          data={currentStep?.params.form}
          currentStep={currentStep}
          onWorkflowInstructionClick={() => handleNextStep()}
        />
      </div>
    ),
    FORM: (
      <div>
        {loading?(<Loader/>):
      (<div className="workflow-step">
        {currentStep?.params.commentsEnabled && workflow?.comment?.visibility?.includes(activeUser.email) && (
          <WorkflowComment comment={workflow?.comment} />
        )}
        <WFForm
          workflowId={workflowId}
          formData={workflow ? workflow.formData : ({ form: currentStep?.params.form } as any)}
          params={currentStep?.params}
          workflowType={workflowType}
          onFinish={handleNextStep}
        />
        <PrevButton />
        <Button
          style={currentStep?.prev ? { marginLeft: "20px" } : undefined}
          type="primary"
          htmlType="submit"
          form="workflow-form"
        >
          {currentStep?.nextStepTitle !== "" ? `${currentStep?.nextStepTitle?.split('|')[0]}` : null}
        </Button>
      </div>)}
      </div>
    ),
    ASSIGN: (
      <div className="workflow-step">
        <WorkflowAssign
          workflowId={workflowId}
          currentStep={currentStep as Step}
          creator={workflow?.createdBy as string}
          onChange={setAssignedUser}
        />
        <div style={{ marginTop: 20 }}>
          <PrevButton />
          <Button
            type="primary"
            onClick={() => {
              if (!currentStep?.params.assignee.includes("@") && assignedUser === "") {
                message.error("Please select a user to assign the workflow to!", durationInSeconds);
              } else {
                if (currentStep?.params.commentsEnabled) {
                  setCommentModalVisible(true);
                } else {
                  handleNextStep();
                }
              }
            }}
          >
            {currentStep?.nextStepTitle !== "" ? `${currentStep?.nextStepTitle?.split('|')[0]}` : null}
          </Button>
        </div>
        <Modal
          visible={commentModalVisible}
          title="Write a comment (optional)"
          onOk={() => {
            handleNextStep(comment);
            setCommentModalVisible(false);
          }}
          onCancel={() => setCommentModalVisible(false)}
        >
          <Input.TextArea
            rows={4}
            onChange={(e) => {
              setComment(e.target.value);
            }}
          />
        </Modal>
      </div>
    ),
    CHECK: (
      <div className="workflow-step">
        <WFForm
          workflowId={workflowId}
          formData={workflow?.formData as any}
          params={currentStep?.params}
          onFinish={(values: any) => handleNextStep(values, "accept")}
          isCheck={true}
        />
        {currentStep?.params.commentsEnabled &&
          workflow?.comment &&
          Object.keys(workflow?.comment).length > 0 &&
          workflow.comment.visibility.includes(activeUser.email) && <WorkflowComment comment={workflow?.comment} />}
        <PrevButton />
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "10px",
          }}
        >
          {currentStep && Object.keys(currentStep?.next).includes("reject") ? (
            <Button
              danger
              type="primary"
              onClick={() => {
                setRejectModalVisible(true);
              }}
            >
              {check_step_reject_string}
            </Button>
          ) : null}
          {currentStep && Object.keys(currentStep?.next).includes("reassign") ? (
            <Button
              danger
              onClick={() => {
                if (currentStep?.params.commentsEnabled) {
                  setCommentModalVisible(true);
                } else {
                  handleNextStep({}, "reassign");
                }
              }}
            >
              {currentStep?.reassignStep}
            </Button>
          ) : null}
          <Button type="primary" htmlType="submit" form="workflow-form">
            {check_step_accept_string}
          </Button>
        </div>
        <Modal
          visible={rejectModalVisible}
          title="Write a comment on why you're rejecting (optional)"
          onOk={() => {
            if (rejectionComment) {
              let vals = {
                message: rejectionComment,
                visibility: [activeUser.email, currentStep?.reassignEmail],
              };
              handleNextStep(vals, "reject");
            } else {
              handleNextStep("", "reject");
            }
            setRejectModalVisible(false);
          }}
          onCancel={() => setRejectModalVisible(false)}
        >
          <Input.TextArea
            rows={4}
            onChange={(e) => {
              setRejectionComment(e.target.value);
            }}
            value={rejectionComment}
          />
        </Modal>
        <Modal
          visible={commentModalVisible}
          title="Write a comment on why you're reassigning (optional)"
          onOk={() => {
            if (comment) {
              let vals = {
                message: comment,
                visibility: [activeUser.email, currentStep?.reassignEmail],
              };
              handleNextStep(vals, "reassign");
            } else {
              handleNextStep("", "reassign");
            }
            setCommentModalVisible(false);
          }}
          onCancel={() => setCommentModalVisible(false)}
        >
          <Input.TextArea
            rows={4}
            onChange={(e) => {
              setComment(e.target.value);
            }}
          />
        </Modal>
      </div>
    ),
    GENERATE: (
      <div className="workflow-step">
        <WorkflowGenerate workflowId={workflowId} workflow={workflow} setGenerating={setGenerating} />
        <PrevButton />
        <Button loading={generating} type="primary" onClick={() => handleNextStep()}>
          {currentStep?.nextStepTitle !== "" ? `${currentStep?.nextStepTitle?.split('|')[0]}` : null}
        </Button>
      </div>
    ),
    DISPLAY: (
      <div className="workflow-step">
        <DottyDisplay />
      </div>
    ),
    SIGNATURE: (
      <div className="workflow-step">
        <Result
          title={` ${workflow?.formData?.form.pdfName} Out For Signatures`}
          subTitle="The form associated with this workflow is currently out for signatures."
          extra={<OutForSignatures workflowId={workflowId} />}
        />
      </div>
    ),
    PRINT: <div>PRINT</div>,
    TABLE: (
      <div className="workflow-step">
        <ResourceTable
          type={currentStep?.params ? currentStep?.params.type : ""}
          onSelect={(user) => setSelectedUser(user)}
        />
        <PrevButton />
        <Button
          type="primary"
          onClick={() => {
            if (selectedUser) {
              handleNextStep();
            } else {
              message.error("You must select a user!", durationInSeconds);
            }
          }}
        >
          {currentStep?.nextStepTitle !== "" ? `${currentStep?.nextStepTitle?.split('|')[0]}` : null}
        </Button>
      </div>
    ),
    MULTIFORM: (
      <div className="workflow-step">
        <WorkflowMultiform workflowId={workflowId} params={currentStep?.params} />
        <PrevButton />
        <Button type="primary" onClick={() => handleNextStep()}>
          {currentStep?.nextStepTitle !== "" ? `${currentStep?.nextStepTitle?.split('|')[0]}` : null}
        </Button>
      </div>
    ),
    SELECT: (
      <div className="workflow-step">
        <WorkflowSelect
          options={currentStep?.params.options}
          onFinish={(option: string) => handleNextStep(null, option)}
        />
        <PrevButton />
        <Button type="primary" htmlType="submit" form="select-form">
          {currentStep?.nextStepTitle !== "" ? `${currentStep?.nextStepTitle?.split('|')[0]}` : null}
        </Button>
      </div>
    ),
    CSV: (
      <div className="workflow-step">
        <WorkflowCSV
          params={currentStep?.params}
          nextStepTitle={currentStep?.nextStepTitle?.split('|')[0] as string}
          prevButton={<PrevButton />}
          onComplete={(data: any) => {
            if (currentStep?.params.type !== "download") {
              handleCSVUpload(data);
            } else {
              handleNextStep();
            }
          }}
        />
      </div>
    ),
    SPAWN: (
      <div className="workflow-step">
        <div>
          <span>You are about to spawn workflows.</span>
        </div>
        <PrevButton />
        <Button type="primary" onClick={() => handleNextStep()}>
          {currentStep?.nextStepTitle !== "" ? `${currentStep?.nextStepTitle?.split('|')[0]}` : null}
        </Button>
      </div>
    ),
    UPDATE: (
      <div className="workflow-step">
        <WorkflowUpdate
          workflowId={workflowId}
          params={currentStep?.params}
          prevButton={<PrevButton />}
          onSubmit={handleNextStep}
        />
      </div>
    ),
    DOWNLOAD: (
      <div className="workflow-step">
        {currentStep?.params.pdfStep && (
          <WorkflowGenerate workflowId={workflowId} workflow={workflow} retrieve={true} />
        )}
        <Button type="primary" onClick={() => handleNextStep()}>
          {currentStep?.nextStepTitle !== "" ? `${currentStep?.nextStepTitle?.split('|')[0]}` : null}
        </Button>
      </div>
    ),
    SENDEMAIL: (
      <div className="workflow-step">
        <div style={{ marginTop: 20 }}>
          <Result
            title={currentStep&&currentStep.title?currentStep?.title:currentStep?.title?.split('|')[0]}
            subTitle={<h3>An Email has been sent to {currentStep?.params.to?.join(', ')}</h3>}
            extra={[
              <PrevButton />,
              <Button type="primary" onClick={() => handleNextStep()}>
                {currentStep?.nextStepTitle !== "" ? `${currentStep?.nextStepTitle?.split('|')[0]}` : null}
              </Button>,
            ]}
          />
        </div>
      </div>
    ),
    FINISH: (
      <div className="workflow-step">
        {currentStep?.params.formStep && (
          <WFForm
            workflowId={workflowId}
            formData={workflow?.formData as any}
            params={currentStep?.params}
            onFinish={(values: any) => handleNextStep(values, "accept")}
            isCheck={true}
          />
        )}
        {currentStep?.params.pdfStep && (
          <WorkflowGenerate workflowId={workflowId} workflow={workflow} retrieve={true} />
        )}
        {currentStep?.params.csvStep && (
          <WorkflowCSVReport workflowId={workflowId} csvStep={currentStep?.params.csvStep} />
        )}
        {currentStep?.params.workflowFile && (
          <WorkflowReport workflowId={workflowId} workflowFile={currentStep?.params.workflowFile} />
        )}
        {currentStep?.params.commentsEnabled && workflow?.comment ? (
          <span>
            <strong>Comment:</strong> {workflow?.comment.message}
            <br />
          </span>
        ) : null}
        {activeUser.email === workflow?.createdBy && !workflow?.workflowArchived && (
          <Button type="primary" onClick={archiveWorkflow} danger>
            Archive Workflow
          </Button>
        )}
        {(currentStep?.params?.show_unarchive_button === undefined || currentStep?.params?.show_unarchive_button === true) && activeUser.email === workflow?.createdBy && workflow?.workflowArchived ? (
            <div style={{width: "calc(100% - 20px)", marginTop: "2.5rem", display: "flex", justifyContent: "center", alignContent: "center"}}>
              <Button type="primary" style={{ backgroundColor: "grey", margin: "0px 5px 0px 5px" }} onClick={unarchiveWorkflow}>
                Unarchive Workflow
              </Button>
              <Button key="dashboard" type="primary" style={{ margin: "0px 5px 0px 5px" }} onClick={() => history.push("/app/dashboard")}>
                Return to Dashboard
              </Button>
            </div>
          ) : (
            <div style={{width: "calc(100% - 20px)", marginTop: "15%", display: "flex", justifyContent: "center", alignContent: "center"}}>
              <Button key="dashboard" type="primary" style={{ margin: "0px 5px 0px 5px" }} onClick={() => history.push("/app/dashboard")}>
                Return to Dashboard
              </Button>
            </div>
          )}
      </div>
    ),
  });

  if (loading || !currentStep) {
    return (
      <Card bordered={false}>
        <Loader />
        <Skeleton active />
      </Card>
    );
  }

  // Get the title when there is no workflow information
  let titleFromStep = currentStep["title"];
  let wfName = "";
  if (currentStep.function === "CHECKLIST") {
    wfName = currentStep.title.split('|')[0];
  }
  for (let key in workflowSteps) {
    let firstStep = workflowSteps[key][0];
    if (firstStep === undefined) continue;
    else {
      if (titleFromStep === firstStep["stepNls"]) {
        wfName = key;
        break;
      }
    }
  }

  // Get the workflow form key if the workflow is not yet created
  let pdfPreviewModal: JSX.Element | null = null;
  let workflowFormKey = null;

  if (workflow) {
    workflowFormKey = workflow.formKey;
  } else {
    for (const allowedWorkflow of allowedWorkflows) {
      if (allowedWorkflow.name == wfName) {
        workflowFormKey = allowedWorkflow.formKey;
      }
    }
  }

  if (
    workflowFormKey &&
    workflowFormKey !== "no_form_present" &&
    workflowFormKey !== "fill_Correct_Form_Name" &&
    workflowFormKey !== "FacultyHostingChecklist" &&
    !workflowFormKey.toLowerCase().includes("aggregate")
  ) {
    pdfPreviewModal = <PdfPreviewModal workflowFormKey={workflowFormKey} />;
  }

  const ResultPanel = ({ title, subTitle = "" }: { title: string; subTitle?: string }) => {
    return (
      <Result
        title={title}
        subTitle={subTitle}
        extra={[
          <Button key="dashboard" type="primary" onClick={() => history.push("/app/dashboard")}>
            Return to Dashboard
          </Button>,
        ]}
      />
    );
  };

  const SidePanel = ({ currentStepStr }: { currentStepStr?: string }) => {
    let currAssignee: string = "";
    let workflowUsers: any = null;
    let currentStep: string = currentStepName;
    let showSteps: boolean = true;

    if (workflow) {
      currAssignee = workflow.assignedTo || workflow.currentAssignee;
      workflowUsers = workflow.users;
      showSteps = workflow.showWorkflowSteps;
    } else if (currentStepStr) {
      currentStep = currentStepStr;
    }

    return (
      <div style={{ padding: "0 0", display: "block" }}>
        <div style={{ paddingBottom: "1rem", display: "flex" }}>
          <Button onClick={openHelpModal}>Help?</Button>
          {workflowSteps &&
          // workflowSteps[workflowName] &&
          // workflowSteps[workflowName].length > 0 &&
          workflowSteps[0]?.step === currentStepName &&
          (activeUser.roles.includes("admin") || activeUser.roles.includes("superadmin")) ? (
            <div style={{ margin: "0px 7.5px 0px 7.5px" }}>
              <Button type="primary" htmlType="button" onClick={handleDirectLinkClick}>
                Direct Link
              </Button>
            </div>
          ) : null}
        </div>
        <div>
          <Stepper
            workflowId={workflowId}
            workflowSteps={workflowSteps}
            allStepsInfo={allStepsInfo}
            users={users}
            currentStep={currentStep}
            currAssignee={currAssignee}
            usersMap={wfUsers}
            wfUsers={workflowUsers}
            logActions={actionsWtActor}
            PdfPreviewProp={pdfPreviewModal}
            showWfStepsStatus={showSteps}
          />
        </div>
      </div>
    );
  };

  if (workflow && workflow.users && !workflow.users.includes(activeUser.email)) {
    return (
      <div>
        <ResultPanel title="Workflow Not found" />
      </div>
    );
  } else if (
    workflow &&
    workflow.currentAssignee &&
    activeUser.email !== workflow.currentAssignee &&
    currentStep.function !== "SIGNATURE"
  ) {
    const sub1 = `You can still go back and make changes if the workflow hasn't been accepted by ${workflow.currentAssignee} yet.`;
    const sub2 = "The next assignee will continue the workflow from here.";
    const sub = workflow.currentAssignee ? sub1 : sub2;
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          {!showSidePanel && (
            <div style={{ width: "65%", padding: "0 2em" }}>
              <ResultPanel title="Successfully Assigned Workflow!" subTitle={sub} />
            </div>
          )}
          {!isMobile && (
            <>
              <Divider type="vertical" style={{ width: "5px", height: "auto" }} />
              <SidePanel />
            </>
          )}
          {isMobile && showSidePanel && <SidePanel />}
          {isMobile && (
            <button onClick={() => setShowSidePanel(!showSidePanel)} style={{ fontSize: "20px", height: "36px" }}>
              ☰
            </button>
          )}
        </div>

        <HelpModal
          helpModalVisible={helpModalVisible}
          setHelpModalVisible={setHelpModalVisible}
          sendDataToParent={handleDataFromHelpModal}
        />
      </>
    );
  } else if (complete.isComplete) {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          {!showSidePanel && (
            <div style={{ width: "100%", padding: "0 2em", overflow: "hidden"}}>
              <Result
                title={complete.title.split('|')[0]}
                subTitle={complete.subtitle}
                extra={[
                  <PrevButton key="prevBtnForResult" isAssign />,
                  <Button
                    style={{ width: "180px" }}
                    key="dashboard"
                    type="primary"
                    onClick={() => history.push("/app/dashboard")}
                  >
                    Return to Dashboard
                  </Button>,
                ]}
              />
            </div>
          )}
          {!isMobile && (
            <>
              <Divider type="vertical" style={{ width: "5px", height: "auto" }} />
              <SidePanel currentStepStr="ForcePushToSecondStep" />
            </>
          )}
          {isMobile && showSidePanel && <SidePanel currentStepStr="ForcePushToSecondStep" />}
          {isMobile && (
            <div onClick={() => setShowSidePanel(!showSidePanel)} style={{ position: "absolute", top: "4rem", right: "5px", fontSize: "20px", fontWeight: "bolder", padding: "5px"}}>
              ☰
            </div>
          )}
        </div>

        <HelpModal
          helpModalVisible={helpModalVisible}
          setHelpModalVisible={setHelpModalVisible}
          sendDataToParent={handleDataFromHelpModal}
        />
      </>
    );
  } else {
    return (
      <Card bordered={false}>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <div
              style={{
                width: isMobile && showSidePanel ? "auto" : "90%",
                display: isMobile && showSidePanel ? "none" : "block",
                padding: isMobile ? "none" : "0 2em",
                overflow: "hidden",
              }}
            >
              <h1
                style={{
                  maxWidth: "calc(100% - 20px)",
                  margin: "inherit auto inherit auto",
                  textAlign: "center",
                  paddingBottom: "10px",
                  fontSize: "1.5em",
                  alignSelf: "center",
                }}
              >
                {
                workflow === null && 
                currentStep != null && 
                typeof currentStep.title === "string" && 
                currentStep?.title.length > 0 ? 
                state.name : (currentStep?.title && currentStep?.title.split('|')[0])
                }
              </h1>

              {currentStep?.instructions && <WorkflowInstructionBox instructions={currentStep?.instructions} />}

              {renderStep()[currentStep?.function]}
            </div>
            {!isMobile && (
              <>
                <Divider type="vertical" style={{ width: "5px", height: "auto" }} />
                <SidePanel />
              </>
            )}
            {isMobile && showSidePanel && <SidePanel />}
            {isMobile && (
              <div onClick={() => setShowSidePanel(!showSidePanel)} style={{ position: "absolute", top: "5px", right: "5px", fontSize: "20px", fontWeight: "bolder", padding: "5px"}}>
                ☰
              </div>
            )}
          </div>
        </div>
        <HelpModal
          helpModalVisible={helpModalVisible}
          setHelpModalVisible={setHelpModalVisible}
          sendDataToParent={handleDataFromHelpModal}
        />
      </Card>
    );
  }
};

export default WorkflowView;
