import React, { useContext, useState, useEffect } from 'react';
import { Form, Select, Divider } from 'antd';
import moment from 'moment'
import FormBuilder from 'antd-form-builder';
import { AuthContext } from 'context/AuthProvider';

interface Props{
    comment: any;
}

function WorkflowComment({comment} : Props) {
    const author = comment.visibility[0]
    // console.log(author,"AUTHORRRR")
    const values = {
        message : `${author}: ${comment.message}`
    }

    const meta = {
        columns: 1,
        fields: [
          { key: 'message', label: "Previous Comments" },
        ]
      }

    return ( 
    
      <>
      
      <Divider />
        {/*}
      <Form layout='vertical'>
          <FormBuilder meta={meta} initialValues={values} viewMode />
      </Form>
      {*/}
        <p style={{fontWeight:'bold'}}>Previous Comments:</p>
        <p><em style={{fontWeight:550}}>{author}</em>{`: ${comment.message}`}</p>
      <Divider />
        
      </>

     );
      
}

export default WorkflowComment;