const toposort = require("toposort");

export const getStepsOrder = (steps: any) => {
  let node: string = steps["begin"];
  const graph = [];

  if (steps[node].function === "CHECKLIST") {
    const checkListStep = steps[node];
    const stepsList = checkListStep.steps.map((item: any) => item.step);
    for (let i = 0; i < stepsList.length; i++) {
      if (i === stepsList.length - 1) {
        graph.push([stepsList[i], checkListStep.next]);
      } else {
        graph.push([stepsList[i], stepsList[i + 1]]);
      }
    }
  } else {
    while (node) {
      const value = steps[node];
      if (value["next"] !== undefined) {
        if (typeof value["next"] === "object") {
          for (let subStepType of Object.keys(value["next"])) {
            if (subStepType === "accept" || subStepType === "advisor_present") {
              const subStep = value["next"][subStepType];
              graph.push([node, subStep]);
            }
          }
        } else {
          graph.push([node, value["next"]]);
        }
      }
      if (graph.length > 0) {
        node = graph[graph.length - 1][1];
      } else {
        node = "";
      }
    }
  }

  const sortedSteps = toposort(graph);
  const stepsWNls: any[] = [];

  for (const step of sortedSteps) {
    let stepNls: string;
    const stepObj: any = {
      step,
    };

    if (steps[step] !== undefined && steps[step]["title"] !== undefined) {
      stepNls = steps[step]["title"];
      stepObj["stepNls"] = stepNls;

      if (stepNls.includes("Denied") || stepNls.includes("Reassign") || stepNls.includes("denied")) continue;

      stepsWNls.push(stepObj);
    }
  }

  return stepsWNls;
};
