import React, { useState, useEffect } from "react";
import { Select, Table,Pagination } from "antd";
import ListView from "./ListView";
import { DASHBOARD_FILTERS_KEY } from "../../views/DashboardView";
import { read } from "fs";
const { Option } = Select;

const OurTableServerSide = ({
  className,
  data,
  columns,
  actionButton,
  activeUser,
  rowSelection,
  onChange,
  currentPage,
  expandable,
  pageSize,
  setPageSize,
  handlePaginationChange,
  totalCount,
}) => {
  const storedFilters = JSON.parse(localStorage.getItem(DASHBOARD_FILTERS_KEY));
  const defaultPageSize =
    storedFilters && storedFilters.pageSize !== 0 ? storedFilters.pageSize : 10;

  // console.log("OurTableServerSide: totalCount-", totalCount, "current", currentPage, "pageSize", pageSize);

  useEffect(() => {
    setPageSize(defaultPageSize);
  }, [storedFilters]);

  const isMobileView = window.innerWidth < 992;

  return (
    <div>
      {isMobileView ? (
        <ListView
          data={data}
          mobColumns={columns}
          pageSize={pageSize}
          actionButton={actionButton}
          activeUser={activeUser}
          rowSelection={rowSelection}
        />
      ) : (
        <>
          <Table
            className={className}
            dataSource={data}
            columns={columns}
            rowSelection={rowSelection}
            onChange={onChange}
            expandable={expandable}
            pagination={false} // Disable internal pagination to customize
          />
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 16 }}>
            <Pagination
              current={currentPage}
              pageSize={pageSize}
              total={totalCount}
              simple
              onChange={handlePaginationChange}
            />
            <Select
              value={pageSize}
              onChange={(value) => handlePaginationChange(currentPage, value)}
              style={{ width: 80, marginLeft: 16 }}
            >
              <Option value={10}>10</Option>
              <Option value={15}>15</Option>
              <Option value={20}>20</Option>
              <Option value={50}>50</Option>
            </Select>
          </div>
        </>
      )}
    </div>
  );
};

export default OurTableServerSide;

OurTableServerSide.defaultProps = {
  className: null,
  actionButton: {},
  rowSelection: null,
  onChange: {},
  expandable: {},
};
