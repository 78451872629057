import { Button, Descriptions, Input } from "antd";
import { AuthContext } from "context/AuthProvider";
import React, { useState, useEffect, useContext } from "react";
import apiCaller from "utils/apiCaller";

interface Props {
  workflowId: string;
  params: {
    properties: string[];
    tableStep?: string;
    formValue?: any;
  };
  prevButton: React.ReactNode;
  onSubmit: any;
}
const WorkflowUpdate = ({ workflowId, params, prevButton, onSubmit }: Props) => {
  const { properties, tableStep, formValue } = params;
  const [users, setUsers] = useState<any[]>([]);
  const [values, setValues] = useState<any>({});
  const { activeUser } = useContext(AuthContext);

  useEffect(() => {
    async function getTableStep() {
      const res = await apiCaller.get("/api/table-step", {
        params: { workflowId, tableStep },
      });
      const resData = res.data.map((user: any) => ({
        ...user,
        roles: user.roles.join("|"),
        impRoles: user.impRoles.join("|"),
      }));
      setUsers(resData);
      const vals: any = {};
      resData.forEach((user: any) => {
        vals[user.id] = {};

        properties.forEach((property: string) => {
          vals[user.id] = {
            [property]: "",
          };
        });
      });
      setValues(vals);
    }
    if (tableStep) {
      getTableStep();
    }

    async function getUserValues() {
      try {
        // Make an async backend call to verify if the user already has an advisor
        const res = await apiCaller.get("/api/user", {
          params: { email: formValue },
        });
        const resData = [res.data];
        setUsers(resData);
        const vals: any = {};
        resData.forEach((user: any) => {
          vals[user.id] = {};

          properties.forEach((property: string) => {
            if (property === "advisor") {
              if (user[property]) {
                vals[user.id][property] = {
                  "previous": user[property]["current"],
                  "current": activeUser.email
                };
              } else {
                const obj = {"previous": "", "current": activeUser.email};
                vals[user.id] = {
                  [property]: obj,
                };
              }
            } else {
              vals[user.id] = {
                [property]: user[property],
              };
            }
          });
        });
        setValues(vals);
      } catch (error) {
        console.error("Error during backend call:", error);
      }
    }

    if (formValue) {
      getUserValues();
    }
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, userId: string, property: string) => {
    setValues((values: any) => {
      const tempObj = {...values}
      if (property === "advisor") {
        tempObj[userId][property]["current"] = e.target.value;
      } else {
        tempObj[userId][property] = e.target.value;
      }
      // console.log(values);
      return tempObj;
    });
  };

  const handleSubmit = () => {
    if (values && Object.keys(values).length > 0) {
      Object.keys(values).forEach((userId: string) => {
        Object.keys(values[userId]).forEach((property: string) => {
          if (property === "roles" || property === "impRoles") {
            if (values[userId][property] !== "") {
              values[userId][property] = values[userId][property].split("|");
            } else {
              values[userId][property] = [];
            }
          }
        });
      });
    }
    onSubmit(values);
  };

  const getPropertyValue = (user: any, property: string) => {
    if (property === "advisor") {
      return user[property] ? formValue ? activeUser.email : user[property]["current"] : formValue ? activeUser.email : ""
    } else {
      return user[property];
    }
  };
  return (
    <div>
      {users.map((user: any) => (
        <Descriptions bordered key={user.id} labelStyle={{ backgroundColor: '#f5f5f5', fontWeight: 'bold' }} column={2} title={user.firstName+" "+user.lastName}>
          <Descriptions.Item label="email">{user.email}</Descriptions.Item>
          {properties.map((property: string) => (
            <Descriptions.Item key={property} label={property} style={{ padding: 10 }}>
              {formValue ? getPropertyValue(user, property) : <Input
                defaultValue={getPropertyValue(user, property)}
                onChange={(e) => handleChange(e, user.id, property)}
              />}
            </Descriptions.Item>
          ))}
        </Descriptions>
      ))}
      {prevButton ? <><br />
        {prevButton}</> : ''}
      <br />
      <Button onClick={handleSubmit}>Submit</Button>
    </div>
  );
};

export default WorkflowUpdate;
