import { DownloadOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button } from "antd";
import download from "downloadjs";
import { useEffect, useState } from "react";

// import { getStorage } from "firebase/storage";
import { Document, Page, pdfjs } from "react-pdf";
import apiCaller from "utils/apiCaller";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// const storage = getStorage();

const WorkflowGenerate = ({ workflowId, workflow, retrieve, setGenerating }: any) => {
  const [file, setFile] = useState("");
  const [numPages, setNumPages] = useState<any | null>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [loadingFlag, setLoadingFlag] = useState<boolean>(true);
  useEffect(() => {
    if (workflow) {
      if (retrieve) {
        retrievePDF();
      } else {
        if (!file) generatePDF();
      }
    }
  }, []);

  const downloadPDF = () => {
    const userId = workflow?.createdBy?.split("@")?.[0];
    const filename = [workflow.workflowFile, userId !== undefined ? `- ${userId}` : ""].join();
    download(file, `${filename}.pdf`, "application/pdf");
  };

  function base64ToArrayBuffer(data: string) {
    var binaryString = window.atob(data);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  const retrievePDF = async () => {
    const res = await apiCaller.get(`/api/combinedDocument`, {
      params: { workflowId },
      validateStatus: (status) => status === 404 || status === 500 || status === 200,
    });

    if (res.status === 200) {
      const buffer = base64ToArrayBuffer(res.data);
      const pdfBlob = new Blob([buffer], { type: "application/pdf" });

      var reader = new FileReader();
      reader.onloadend = function () {
        var base64data = reader.result;
        setFile(base64data as string);
      };
      reader.readAsDataURL(pdfBlob);
    } else {
      setLoadingFlag(false);
    }
  };

  const generatePDF = async () => {
    const { form } = workflow?.formData;
    const { formName } = form;
    setGenerating(true);
    const formDataRes = await apiCaller.get(`/api/all-form-data`, {
      params: {
        workflowId,
      },
    });
    let formData = formDataRes.data;
    const values: any = {};
    for (let formDataValues of formData) {
      for (let key in formDataValues) {
        if (
          (key.toLowerCase() === "faculty" || key.toLowerCase().includes("prof")) &&
          !key.toLowerCase().includes("comments") &&
          formDataValues[key] !== "N/A"
        ) {
          const email = formDataValues[key];
          const userRes = await apiCaller.get(`/api/user`, {
            params: {
              email,
            },
          });
          for (const k in userRes?.data) {
            values[`${key}#${k}`] = userRes.data[k];
          }
          values[key] = userRes?.data?.firstName+" "+userRes?.data?.lastName;
        } else {
          values[key] = formDataValues[key];
        }
      }
    }
    if ("step" in values) {
      delete values["step"];
    }
    const res = await apiCaller.post(`/api/generate-pdf`, values, {
      params: {
        form: formName,
      },
    });
    const pdfData = res.data;
    const buffer = base64ToArrayBuffer(pdfData);
    const pdfBlob = new Blob([buffer], { type: "application/pdf" });
    // display generated pdf
    var reader = new FileReader();
    reader.onloadend = function () {
      var base64data = reader.result;
      setFile(base64data as string);
    };
    reader.readAsDataURL(pdfBlob);
    try {
      await apiCaller.post("/api/pdf-url", { workflowId, pdfData });
    } catch (e) {
      console.error(e);
    }
    setGenerating(false);
  };

  const changePage = (offset: number) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };

  return (
    <div
      className="container"
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        paddingBottom: 25,
        overflow: "auto",
      }}
    >
      {file !== "" ? (
        <>
          <Button type="primary" shape="round" icon={<DownloadOutlined />} onClick={downloadPDF}>
            Download PDF
          </Button>
          <Document
            file={file}
            onLoadError={(error) => alert("Error while loading document! " + error.message)}
            onSourceError={(error) => alert("Error while loading document! " + error.message)}
            onLoadSuccess={({ numPages }) => {
              setNumPages(numPages);
              setPageNumber(1);
            }}
          >
            {<Page width={650} pageNumber={pageNumber} />}
          </Document>
          <p>
            Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
          </p>
          <div
            style={{
              paddingBottom: 20,
            }}
          >
            <Button type="default" disabled={pageNumber <= 1} onClick={previousPage}>
              <LeftOutlined />
              Previous Page
            </Button>
            <Button type="default" disabled={pageNumber >= numPages} onClick={nextPage}>
              Next Page <RightOutlined />
            </Button>
          </div>
        </>
      ) : (
        <>
          {(() => {
            if (loadingFlag) {
              return <div>Loading...</div>;
            } else {
              return <div>No Agreement Created</div>;
            }
          })()}
        </>
      )}
    </div>
  );
};

export default WorkflowGenerate;
