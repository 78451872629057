import React, { useState } from "react";
import { Pagination, Space, Radio } from "antd";
import { DASHBOARD_FILTERS_KEY } from "../../views/DashboardView";
import {Badge} from 'antd';

const getWFNameAndDateAsDiv = (text, record) => {
  var divStyle = { fontSize: "10px" };
  var margin = { margin: "0px" };

  const date = new Date(record.createdAt._seconds * 1000);
  const dateTime =
    "(" +
    `${date.toLocaleDateString()}` +
    " " +
    `${date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}` +
    ")";
  return (
    <div>
      {" "}
      <p style={margin}>{text}</p>
      <p style={divStyle}>{dateTime}</p>
    </div>
  );
};

const renderValue = (value, dataIndex, column,record) => {
  if (Array.isArray(value) && value.length === 2 && typeof value[0] === "string" && typeof value[1] === "string") {
    return <>{`${value[1]} (${value[0].split(".")[0]})`}</>;
  } else if (dataIndex === "impersonatedAt") {
    const impersonatedDate = new Date(value._seconds * 1000).toLocaleDateString();
    return <>{impersonatedDate}</>;
  } else if (dataIndex === "currentStep") {
    for (const step of record.workflowSteps) {
      if (step.step === record.currentStep) {
        return step.stepNls.split('|')[0];
      }
    }
    return <></>; 
  }
  else if (dataIndex === "workflowFile") {
    return getWFNameAndDateAsDiv(value,record)
  }
  else if (dataIndex === "currentAssigneeComplete") {
    return(
    record.currentAssigneeComplete || record.workflowComplete ? (
      <Badge color="green" text="complete" />
    ) : (
      <Badge color="red" text="incomplete" />
    ))
  }
   else {
    return value;
  }
};

const generateListItems = (record, mobColumns) => {
  return mobColumns.map((column) => {
    const dataIndex = column.dataIndex;
    const title = column.title;
    const value = record[dataIndex];
    const val = renderValue(value, dataIndex,column, record);
    return (
      val && (
        <div key={dataIndex} style={{ fontSize: "13px" }}>
          <strong>{title}:</strong> {val}
        </div>
      )
    );
  });
};

const ListItem = ({ record, mobColumns, actionButton, rowSelection }) => {
  const [showDetails, setShowDetails] = useState(false);
  const [selected, setSelected] = useState(false);

  const handleRadioChange = () => {
    setSelected(!selected);
    rowSelection.onChange([record.key], [record]);
  };

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };
  const visibleLines = generateListItems(record, mobColumns).slice(0, 6);

  return (
    <div style={{ margin: "15px", maxWidth: "300px" }}>
      {rowSelection != null && <Radio checked={selected} onChange={handleRadioChange} />}
      {visibleLines}
      {generateListItems(record, mobColumns).length > 7 && (
        <div style={{ cursor: "pointer", color: "blue", fontSize: "12px" }} onClick={toggleDetails}>
          {showDetails ? "Show less" : "Show more"}
        </div>
      )}
      <div style={{ padding: "5px" }}>
        <Space size="middle">
          {actionButton &&
            Array.isArray(actionButton) &&
            actionButton.map((button, index) => (
              <React.Fragment key={index}>{button.render && button.render(null, record)}</React.Fragment>
            ))}
        </Space>
      </div>
    </div>
  );
};

const ListView = ({ data, mobColumns, pageSize, actionButton, rowSelection, handleRowSelectionChange }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeMob, setPageSizeMob] = useState(pageSize);
  const indexOfLastRecord = currentPage * pageSizeMob;
  const indexOfFirstRecord = indexOfLastRecord - pageSizeMob;
  const currentRecords = data.slice(indexOfFirstRecord, indexOfLastRecord);
  const storedFilters = JSON.parse(localStorage.getItem(DASHBOARD_FILTERS_KEY));

  const handlePaginationChange = (pageNumber, newPageSize) => {
    setCurrentPage(pageNumber);
    setPageSizeMob(newPageSize);
    if (storedFilters) {
      storedFilters.pageSize = newPageSize;
      localStorage.setItem(DASHBOARD_FILTERS_KEY, JSON.stringify(storedFilters));
    }
  };

  return (
    <div>
      {currentRecords.map((record) => (
        <ListItem
          key={record.id}
          record={record}
          mobColumns={mobColumns}
          actionButton={actionButton}
          rowSelection={rowSelection}
        />
      ))}
      {data.length > 0 && (
        <div style={{ width: "100%", textAlign: "center", marginTop: "20px" }}>
          <Pagination
           className="pagination-mob"
            current={currentPage}
            pageSize={pageSizeMob}
            total={data.length}
            showSizeChanger={true}
            pageSizeOptions={['10', '15', '20']}
            onChange={(pageNumber, pageSizeMob) => handlePaginationChange(pageNumber, pageSizeMob)}
            onShowSizeChange={(pageNumber, pageSizeMob) => handlePaginationChange(pageNumber, pageSizeMob)}
          />
        </div>
      )}
    </div>
  );
};

export default ListView;
