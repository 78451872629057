import { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Card, Col, Result, Row, Skeleton, Tabs } from "antd";
import _ from "lodash";

import { AuthContext } from "context/AuthProvider";
import apiCaller from "utils/apiCaller";
import { processWorkflowForTable } from "helpers";
import AdminTable from "components/admin/AdminTable";
import "../index.css";
import ReactLoading from "react-loading";
import { DASHBOARD_FILTERS_KEY } from "./DashboardView";
import { Moment } from "moment";

const { TabPane } = Tabs;
type WorkflowType = 'incomplete' | 'completed' | 'all' | 'dev';
const AdminDashboardView = () => {
  const { currentUser } = useContext(AuthContext);
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [loadingCounts, setLoadingCounts] = useState(false); // New state for loading counts
  const [users, setUsers] = useState<any>({});
  const [workflows, setWorkflows] = useState<any[]>([]);
  const [devWorkflows, setDevWorkflows] = useState<any[]>([]);
  const [incompleteWorkflows, setIncompleteWorkflows] = useState<any[]>([]);
  const [completedWorkflows, setCompletedWorkflows] = useState<any[]>([]);
  const [pageTrackers, setPageTrackers] = useState<{
    [key in WorkflowType as string]: Map<number, number | null>;
  }>({
    incomplete: new Map<number, number | null>(),
    completed: new Map<number, number | null>(),
    all: new Map<number, number | null>(),
    dev: new Map<number, number | null>(),
  });

  // State to store the total counts for each type of workflow
  const [totalIncompleteWorkflows, setTotalIncompleteWorkflows] = useState<number>(0);
  const [totalCompletedWorkflows, setTotalCompletedWorkflows] = useState<number>(0);
  const [totalAllWorkflows, setTotalAllWorkflows] = useState<number>(0);
  const [totalDevWorkflows, setTotalDevWorkflows] = useState<number>(0);
  const storedFilters = JSON.parse(localStorage.getItem(DASHBOARD_FILTERS_KEY) || "");
  const defaultPageSize = storedFilters && storedFilters.pageSize !== 0 ? storedFilters.pageSize : 10;
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const[totalData,setTotalData]=useState<number>(0);
  const [activeTab, setActiveTab] = useState<string>("incomplete");
  const [currentPage, setCurrentPage] = useState(1);
  const [accessedPages, setAccessedPages] = useState(new Set([1]));
  const [datesSelected, setDatesSelected] = useState<[Moment, Moment] | undefined>();
  const [searchText, setSearchText] = useState<string>("");
  const previousPageRef = useRef(currentPage);
  useEffect(() => {
    if (currentUser.roles.includes("superadmin") || currentUser.roles.includes("admin")) {
      fetchUsers(); // Fetch users once
      fetchCounts(); // Fetch counts asynchronously
    }
  }, []);

  const fetchUsers = async () => {
    try {
      const usersMapResp = await apiCaller.get("/api/usersMap", {
        params: { key: "email", value: ["firstName", "lastName"] },
      });
      const usersMap = usersMapResp.data;
      setUsers(usersMap);
      fetchWorkflows("incomplete", usersMap); // Fetch incomplete workflows initially
    } catch (err) {
      console.error("Error in fetching the users: ", err);
    }
  };

  const fetchCounts = async () => {
    setLoadingCounts(true);
    try {
      const countsData = await apiCaller.get("/api/workflows/counts");
      setTotalIncompleteWorkflows(countsData.data?.incomplete || 0);
      setTotalCompletedWorkflows(countsData.data?.completed || 0);
      setTotalAllWorkflows(countsData.data?.all || 0);
      setTotalDevWorkflows(countsData.data?.dev || 0);
    } catch (err) {
      console.error("Error in fetching the counts: ", err);
    }
    setLoadingCounts(false);
  };

  const fetchWorkflows = async (workflowType: string, usersMap?: any, pageNumber = 1, searchValue?: string, dateRange?: [Moment, Moment]|undefined) => {
    setLoading(true);
    try {
      const lastVisibleId = pageTrackers[workflowType].get(pageNumber - 1) || null;
      console.log("fetchWorkflows->pageNumber", pageNumber, "pageSize", pageSize, "lastVisibleId", lastVisibleId, workflowType, "searchValue",searchValue,"dateRange",dateRange?.map(date => date.toISOString()));
      const workflowsData = await apiCaller.get("/api/workflows", {
        params: {
          pageSize,
          lastVisibleId: lastVisibleId || 0,
          workflowType,
          searchValue,
          dateRange:dateRange?.map(date => date.toISOString()),
        },
      });

      if (!usersMap) usersMap = users;
      let total=(((currentPage-1)*pageSize)+workflowsData.data?.counts)
      console.log("total",total,"currentPage",currentPage,"pageSize",pageSize)
      setTotalData(total)
      const workflows = workflowsData.data?.workflows?.map((workflow: any) =>
        processWorkflowForTable(workflow, usersMap)
      );


      if (workflowType === "incomplete") {
        setIncompleteWorkflows([...workflows]);
        updateLastVisibleId(workflowType, pageNumber, workflows);
      } else if (workflowType === "completed") {
        setCompletedWorkflows([...workflows]);
        updateLastVisibleId(workflowType, pageNumber, workflows);
      } else if (workflowType === "all") {
        setWorkflows([...workflows]);
        updateLastVisibleId(workflowType, pageNumber, workflows);
      }else{
        setDevWorkflows([...workflows]);
        updateLastVisibleId(workflowType, pageNumber, workflows);
      }
    } catch (err) {
      console.error("Error in fetching the workflows: ", err);
    }
    setLoading(false);
  };

  const updateLastVisibleId = (workflowType: string, pageNumber: number, workflows: any[]) => {
    const lastWorkflow = workflows[workflows.length - 1];
    setPageTrackers((prevTrackers) => {
      const newTrackers = { ...prevTrackers };
      newTrackers[workflowType].set(pageNumber, lastWorkflow?.id || null);
      return newTrackers;
    });
  };

  const handleTabChange = (key: string) => {
    setActiveTab(key);
    setCurrentPage(1);
    if (key === "incomplete" && incompleteWorkflows.length === 0) {
      fetchWorkflows("incomplete");
    } else if (key === "completed" && completedWorkflows.length === 0) {
      fetchWorkflows("completed");
    } else if (key === "all" && workflows.length === 0) {
      fetchWorkflows("all");
    } else if (key === "dev" && devWorkflows.length === 0) {
      fetchWorkflows("dev");
    }
  };

  useEffect(() => {
    if (accessedPages.has(currentPage) || currentPage === previousPageRef.current + 1) {
      fetchWorkflows(activeTab, users, currentPage);
    }
    // Update previousPageRef after workflows are fetched
    previousPageRef.current = currentPage;
  }, [pageSize, currentPage]);
  
  const handlePaginationChange = (pageNumber: any, newPageSize: any) => {
    console.log("handlePaginationChange", pageNumber, newPageSize);
    if (accessedPages.has(pageNumber) || pageNumber === currentPage + 1) {
      setPageSize(newPageSize);
      setCurrentPage(pageNumber);
      if (storedFilters) {
        storedFilters.pageSize = newPageSize;
        localStorage.setItem(DASHBOARD_FILTERS_KEY, JSON.stringify(storedFilters));
      }
      setAccessedPages((prev) => new Set(prev).add(pageNumber)); // Track accessed page
    }
  };

  const Info = ({ title, value }: any) => (
    <div style={{ position: "relative", textAlign: "center" }}>
      <span
        style={{
          color: "rgba(0,0,0,0.45)",
          display: "inline-block",
          marginBottom: "4px",
          fontSize: "16px",
          lineHeight: "22px",
        }}
      >
        {title}
      </span>
      <p style={{ margin: 0, fontSize: "24px", lineHeight: "32px" }}>{value}</p>
    </div>
  );

  if (!(currentUser.roles.includes("superadmin") || currentUser.roles.includes("admin"))) {
    return (
      <Result
        status="403"
        title="403"
        subTitle="Sorry, you are not authorized to access this page."
        extra={
          <Button key="dashboard" type="primary" onClick={() => history.push("/app/dashboard")}>
            Return to Dashboard
          </Button>
        }
      />
    );
  }
  const renderAdminTable = (workflowType: WorkflowType, data: any[], title: string) => (
    <AdminTable
      data={data}
      usersMap={users}
      handleChange={(searchtext: string, dates: [Moment, Moment] | undefined) => fetchWorkflows(workflowType, undefined, 1, searchtext, dates)}
      currentPage={currentPage}
      pageSize={pageSize}
      setPageSize={setPageSize}
      handlePaginationChange={handlePaginationChange}
      totalCount={totalData}
      datesSelected={datesSelected}
      setDatesSelected={setDatesSelected}
      searchText={searchText}
      setSearchText={setSearchText}
    />
  );

  return (
    <div>
      {loading ? (
        <div className="spinner">
          <h1>Loading...</h1>
          <ReactLoading type="spinningBubbles" color="#990000" />
        </div>
      ) : (
        <div>
          <Card>
            <Row>
              {loadingCounts ? (
                <ReactLoading type="spinningBubbles" color="#990000" />
              ) : (
                <>
                  <Col sm={6} xs={24}>
                    <Info title="Incomplete Workflows" value={totalIncompleteWorkflows} />
                  </Col>
                  <Col sm={6} xs={24}>
                    <Info title="Completed Workflows" value={totalCompletedWorkflows} />
                  </Col>
                  <Col sm={6} xs={24}>
                    <Info title="All Workflows" value={totalAllWorkflows} />
                  </Col>
                  <Col sm={6} xs={24}>
                    <Info title="Dev Workflows" value={totalDevWorkflows} />
                  </Col>
                </>
              )}
            </Row>
          </Card>

          <Card bordered={false} style={{ marginTop: "24px" }}>
          <Tabs activeKey={activeTab} onChange={handleTabChange}>
            <TabPane tab="Incomplete Workflows" key="incomplete">
              {renderAdminTable("incomplete", incompleteWorkflows, "Incomplete Workflows")}
            </TabPane>
            <TabPane tab="Completed Workflows" key="completed">
              {renderAdminTable("completed", completedWorkflows, "Completed Workflows")}
            </TabPane>
            <TabPane tab="All Workflows" key="all">
              {renderAdminTable("all", workflows, "All Workflows")}
            </TabPane>
            <TabPane tab="Dev Workflows" key="dev">
              {renderAdminTable("dev", devWorkflows, "Dev Workflows")}
            </TabPane>
          </Tabs>
          </Card>
        </div>
      )}
    </div>
  );
};
export default AdminDashboardView;
