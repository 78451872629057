import {
    Button,
    Card,
  } from "antd";
import WorkflowInstructionBox from "./WorkflowInstructionBox";

const WorkflowInstruction = (props: any)=> {
    return (
        <>
        <div style={{ marginTop: 20 }}>
          <Button
            type="primary"
            onClick={()=> {
                props.onWorkflowInstructionClick();
            }}
          >
            {props.currentStep?.nextStepTitle !== ""
              ? `${props.currentStep?.nextStepTitle.split('|')[0]}`
              : null}
          </Button>
        </div>
        </>
    )
};

export default WorkflowInstruction;