import { Option } from 'antd/lib/mentions';
import React from 'react';
import { SingleValue } from 'react-select';
import AsyncSelect from 'react-select/async';

interface Option {
  value: string;
  label: string;
  sbuid: string;
}

interface SearchDropdownProps {
  fetchOptions: (searchValue: string) => Promise<Option[]>;
}

const SearchDropdown = ({
  value,
  disabled,
  children
}: any) => {
  const valueProp: Option = { value: value, label: value, sbuid: "" };

  const { handleOptionSelect, fetchOptions } = children;

  const loadOptions = async (inputValue: string) => {
    if (inputValue.length < 1) {
      return Promise.resolve([]); // Return an empty array if input is too short
    }
    
    const options = await fetchOptions(inputValue);

    // If no results, add an option based on the search text
    if (options.length === 0) {
      return [
        {
          value: inputValue,
          label: inputValue,
          sbuid: ""
        }
      ];
    }
    
    return options;
  };

  const handleSelect = (selectedOption: SingleValue<Option>) => {
    handleOptionSelect(selectedOption); // Pass the selected option (or null) to the parent component
  };

  return (
    <AsyncSelect
      isDisabled={disabled}
      cacheOptions
      loadOptions={loadOptions}
      defaultOptions={value} // Set default options if needed
      value={valueProp}
      onInputChange={(inputValue) => inputValue} // Control input change behavior
      onChange={handleSelect}
    />
  );
};

export default SearchDropdown;
