import { Select } from "antd";

const SelectSearch = ({ value, disabled, onChange, children }: any) => {
    return (
      <Select
        showSearch
        placeholder="Search to Select"
        optionFilterProp="children"
        defaultValue={value}
        disabled={disabled}
        filterOption={(input, option) => {
          const options = option?.children.map((o: string) => o.toLowerCase());
          return options.find((opt: string) => opt.includes(input.toLowerCase()));
        }}
        filterSort={(optionA, optionB) => {
          return optionA.value.toLowerCase().localeCompare(optionB.value.toLowerCase());
        }}
        onChange={onChange}
      >
        {children}
      </Select>
    );
  };

  export default SelectSearch;