import { DownloadOutlined } from "@ant-design/icons";
import type { SelectProps } from "antd";
import { Button, Form, Select } from "antd";
import { graphviz } from "d3-graphviz";
import { useCallback, useEffect, useRef, useState } from "react";
import apiCaller from "utils/apiCaller";
import { Graphviz } from "../../graphic-utils/Graphviz";
import "./DottyDisplay.css";
const saveSvgAsPng = require("save-svg-as-png");

const getStepsOrderForDotty = (steps: any) => {
  /**
   * Returns 2D array(graph) with inner arrays having following values
   * [eachNode - currentStep Name , subStep - step following currentStep Name, titleOfCurrentStep - title of currentStep , functionNameOfCurrentStep, subStepLabel - if "next" has multiple steps that it is pointing to]
   * @param steps All steps on one particular WF
   */
  let beginNode: string = steps["begin"];
  var graph = [];
  graph.push(["start", beginNode, "", "", ""]);
  for (const eachNode of Object.keys(steps)) {
    let value = steps[eachNode];
    let functionNameOfCurrentStep = value["function"];
    let titleOfCurrentStep = value["title"];
    if (value["next"] !== undefined) {
      if (typeof value["next"] === "object") {
        for (let subStepLabel of Object.keys(value["next"])) {
          let subStep = value["next"][subStepLabel];
          graph.push([
            eachNode,
            subStep,
            titleOfCurrentStep,
            functionNameOfCurrentStep,
            subStepLabel,
          ]);
        }
      } else {
        graph.push([
          eachNode,
          value["next"],
          titleOfCurrentStep,
          functionNameOfCurrentStep,
          "",
        ]);
      }
    }
  }
  return graph;
};

function DottyDisplay() {
  const dataFinal = `
    // Default state for Dotty on page-load => No dot graph
    strict digraph "" {
	    node [fontsize=8.0, shape=box];
	    edge [penwidth=0.5];
      // start -> end
      // start [shape=Mdiamond];
      // end [shape=Msquare];
    }
    `;

  const [dataForDotty, setDataForDotty] = useState<string>(dataFinal);
  const [resetForDotty, setResetForDotty] = useState<string>("hide"); // Default state for Reset Dotty button on page-load
  const [filenameDownloadForDotty, setfilenameDownloadForDotty] =
    useState<string>("none");
  const [workflowStepsDotty, setWorkflowStepsDotty] = useState<any>({});

  const layout = {
    labelCol: { span: 0 },
    wrapperCol: { span: 16 },
  };

  const style = {
    width: window.innerWidth - 500,
    height: window.innerHeight,
  };

  const graphvizRoot = useRef(null);

  useEffect(() => {
    loadWorkflowSteps();
    if (graphvizRoot.current) {
      const { id } = graphvizRoot.current;
      // use DOM id update style
      const myMaybeNullElement = document.getElementById(`${id}`);
      if (myMaybeNullElement === null) {
        alert("Error in generating Dotty!");
      }
      graphviz(`#${id}`);
    }
  }, [graphvizRoot]);

  async function loadWorkflowSteps() {
    const res = await apiCaller.get("/api/all-workflow-steps");
    const stepsData = res.data.map((d: any) => ({
      key: d.id,
      ...d,
    }));

    let allStepsWorkflowDotty: any = {};
    for (let workflow of stepsData) {
      let workflowName = workflow["name"];
      let sectionName = workflow["section"];
      let stepsViewForDotty = getStepsOrderForDotty(workflow["steps"]);
      allStepsWorkflowDotty[workflowName + " - " + sectionName] =
        stepsViewForDotty;
    }
    setWorkflowStepsDotty(allStepsWorkflowDotty);
  }

  const reset = useCallback(() => {
    /**
     * Resets dotty diagram
     * Bug: However, on Click, image is moved to the top, and resetZoom has no options to resize the image
     */

    if (graphvizRoot.current) {
      const { id } = graphvizRoot.current;
      // const { options } = ref.current.props.options;
      graphviz(`#${id}`).resetZoom();
    }
  }, [graphvizRoot]);

  const download = () => {
    /**
     * Downloads dotty diagram, but reset has to work before download.
     */
    if (graphvizRoot.current) {
      const { id } = graphvizRoot.current;
      const myMaybeNullElement = document.getElementById(`${id}`);

      if (myMaybeNullElement === null) {
        alert("Error");
      } else {
        const arg1 = myMaybeNullElement.childNodes[0]; // <- no error
        saveSvgAsPng.saveSvgAsPng(
          // workaround: find SVG node
          arg1,
          `dagviewer-${filenameDownloadForDotty as string}.png`,
          {
            scale: 1.0,
            backgroundColor: "white",
          }
        );
      }
    }
  };

  function renameStepWithTitle(newFilteredDataOfChosenWF: Array<any>) {
    /**
     * Maps step name with step title
     * @param newFilteredDataOfChosenWF Input WF step data seletcted by user from dropdown
     */
    const map = new Map();
    for (const eachArray of newFilteredDataOfChosenWF) {
      if (eachArray[0] === "start") {
        map.set(eachArray[0], "start");
      } else {
        map.set(eachArray[0], eachArray[2]);
      }
    }
    newFilteredDataOfChosenWF.forEach((element, index) => {
      if (typeof map.get(newFilteredDataOfChosenWF[index][0]) !== "undefined") {
        newFilteredDataOfChosenWF[index][0] = map.get(
          newFilteredDataOfChosenWF[index][0]
        );
        newFilteredDataOfChosenWF[index][1] = map.get(
          newFilteredDataOfChosenWF[index][1]
        );
      }
    });
    return newFilteredDataOfChosenWF;
  }

  function processData(newFilteredDataOfChosenWF: Array<any>) {
    /**
     * Forms and returns the entire stringified data as redquired by dotty engine
     * @param newFilteredDataOfChosenWF Input WF step data seletcted by user from dropdown
     */
    const mappedDataWithLabel: any = renameStepWithTitle(
      newFilteredDataOfChosenWF
    );
    // console.log(mappedDataWithLabel, 'mappedDataWithLabel');

    let mappedDataWithLabelString: String = "";
    for (var i = 0; i < mappedDataWithLabel.length; i++) {
      // console.log(mappedDataWithLabel[i][1], mappedDataWithLabel[i][4]);
      if (
        mappedDataWithLabel[i][1] !== undefined &&
        mappedDataWithLabel[i][4] !== ""
      ) {
        // console.log('case 1');
        mappedDataWithLabelString = mappedDataWithLabelString.concat(
          '"' +
            mappedDataWithLabel[i][0].toString() +
            '"'.concat("->") +
            '"'.concat(mappedDataWithLabel[i][1]) +
            '"'.concat("[label=" + mappedDataWithLabel[i][4] + "];")
        );
      } else if (
        mappedDataWithLabel[i][1] !== undefined &&
        mappedDataWithLabel[i][4] === ""
      ) {
        // console.log('case 2');
        mappedDataWithLabelString = mappedDataWithLabelString.concat(
          '"' +
            mappedDataWithLabel[i][0].toString() +
            '"'.concat("->") +
            '"'.concat(mappedDataWithLabel[i][1] + '";')
        );
      } else if (
        mappedDataWithLabel[i][1] === undefined &&
        mappedDataWithLabel[i][4] !== ""
      ) {
        // console.log('case 3');
        continue;
        // mappedDataWithLabelString = mappedDataWithLabelString.concat( '"'+mappedDataWithLabel[i][0].toString()+'"'.concat('->')+'"'.concat(mappedDataWithLabel[i][1] + '";') )
      } else {
        // console.log('case 4');
        mappedDataWithLabelString = mappedDataWithLabelString.concat(
          '"' +
            mappedDataWithLabel[i][0].toString() +
            '"'.concat("->") +
            '"'.concat("end") +
            '";'
        );
      }
    }

    // mappedDataWithLabelString = mappedDataWithLabelString.replace(/[.*+?^${}/()|\\]/g, '\\$&');
    // mappedDataWithLabelString =  mappedDataWithLabelString.replaceAll('+', '').replaceAll('/', '');
    // console.log(mappedDataWithLabelString, 'mappedDataWithLabelString');

    let startString = `
      strict digraph "" {
        overlap = scale 
      node [fontsize=8.0,
        shape=box
      ];
      edge [penwidth=0.5]; `;
    //https://graphviz.org/docs/attrs/overlap/

    let endString = `
      start [shape=Mdiamond];
        end [shape=Msquare];
        }`;

    let finalStringifiedData = startString.concat(
      mappedDataWithLabelString.toString().concat(endString)
    );

    return finalStringifiedData;
  }

  function handleChange(input: any) {
    /**
     * Handles change event of the dropdown in dotty workflow
     * @param input Input WF seletcted by user from dropdown
     */
    let newData: any;
    for (const [key] of Object.entries(workflowStepsDotty)) {
      if ((input as string).toLowerCase() === key.toLowerCase()) {
        newData = workflowStepsDotty[key];
        break;
      }
    }

    let finalStringifiedData: string = processData(newData);
    setDataForDotty(finalStringifiedData);
    setResetForDotty("show");
    setfilenameDownloadForDotty(input.toString());
  }

  const options: SelectProps<any>["options"] = []; // Dropdown form options to display
  for (const value of Object.keys(workflowStepsDotty)) {
    options.push({
      label: value,
      value,
      // disabled: i === 10,
    });
  }

  return (
    <>
      <Form {...layout} layout="vertical" name="control-hooks">
        <Form.Item
          name="DottySelect"
          label="Choose the workflow to display"
          rules={[{ required: true }]}
        >
          <Select
            showSearch
            style={{ width: 500 }}
            placeholder="Search to Select"
            optionFilterProp="children"
            onChange={handleChange}
            options={options}
            filterOption={(input, option: any) =>
              (option.label as string)
                .toString()
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA.label as string)
                .toString()
                .toLowerCase()
                .localeCompare(
                  (optionB.label as string).toString().toLowerCase()
                )
            }
          />
        </Form.Item>
      </Form>
      <div id="container" style={{ textAlign: "center" }}>
        <Button
          type="primary"
          style={{ float: "left", marginRight: "10px" }}
          id="DottyReset"
          className={resetForDotty}
          onClick={reset}
        >
          {" "}
          Reset dotty diagram
        </Button>
        <Button
          type="primary"
          style={{ marginLeft: "40px" }}
          id="DottyDownload"
          className={resetForDotty}
          onClick={download}
          icon={<DownloadOutlined />}
        >
          {" "}
          Download dotty diagram
        </Button>
      </div>

      <div
        style={{
          ...style,
          position: "relative",
        }}
      >
        <Graphviz
          dot={dataForDotty}
          options={{ zoom: true, useWorker: false, ...style }}
          ref={graphvizRoot} // for options: Refer - https://www.npmjs.com/package/d3-graphviz/v/3.0.0-alpha.2
        />
      </div>
    </>
  );
}

export default DottyDisplay;
