
import _ from 'lodash';
import JSZip from "jszip";
import apiCaller from "utils/apiCaller";
import { saveAs } from "file-saver";
import { message } from "antd";
export const JSZipUtils = require("jszip-utils");


export const durationInSeconds = 2;
export const getFormattedTime = () => {
    const today = new Date();
    const y = today.getFullYear();
    const m = today.getMonth() + 1;
    const d = today.getDate();
    const h = today.getHours();
    const mi = today.getMinutes();
    const s = today.getSeconds();
    return y + "-" + m + "-" + d + "-" + h + "-" + mi + "-" + s;
  };
  const mergeById = (a1: any, a2: any) =>
    a1.map((itm: any) => ({
      ...a2.find((item: any) => item.worflowID === itm.id && item),
      ...itm,
    }));

  function timeConverter(UNIX_timestamp: any) {
    const a = new Date(UNIX_timestamp * 1000);
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const year = a.getFullYear();
    const month = months[a.getMonth()];
    const date = a.getDate();
    const hour = a.getHours();
    const min = a.getMinutes();
    const sec = a.getSeconds();
    const time = date + " " + month + " " + year + " " + hour + ":" + min + ":" + sec;
    return time;
  }

  export const getFormData = async (idsList: any,setLoading:any,filteredDataCheckBox:any) => {
    if (idsList.length > 0) {
      setLoading(true);
      const res = await apiCaller.post(
        "/api/get-all-form-data-batched",
        {
          workflowList: idsList,
        },
        { timeout: 600000 }
      );
      setLoading(false);
      const allData = res.data;
      let mergedObjectsToCSV = mergeById(filteredDataCheckBox, allData);
      let newmergedList = _.groupBy(mergedObjectsToCSV, "workflowFile");
      var blobList: any[] = [];
      for (const property in newmergedList) {
        blobList.push(await downloadExcel(property, newmergedList[property]));
      }
      return blobList;
    }
  };

  export const downloadExcel = async (WFfileName: any, mergedObjectsToCSV: any) => {
    interface workflowFormsCSVData {
      WFName: string;
      blobData: any;
    }
    const separator = ",";
    const keys = Object.keys(mergedObjectsToCSV[0]);
    const csvContent =
      keys.join(separator) +
      "\n" +
      mergedObjectsToCSV
        .map((row: any) => {
          return keys
            .map((k) => {
              let cell = row[k] === null || row[k] === undefined ? "" : row[k];

              if (typeof cell === "object" && cell !== null && cell["_seconds"] !== undefined) {
                cell = JSON.stringify(timeConverter(cell["_seconds"]));
              } else if (typeof cell === "object") {
                cell = JSON.stringify(cell).replaceAll(",", "-");
              } else {
                cell = cell.toString();
              }
              return cell;
            })
            .join(separator);
        })
        .join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    const element: workflowFormsCSVData = {
      WFName: WFfileName,
      blobData: blob,
    };
    return element;
  };


export const downloadArchive = async (setLoading:any,filteredDataCheckBox:any) => {
    try {
      const idsList = filteredDataCheckBox.map((obj: any) => obj.id);
      var zip = new JSZip();
      var countPDF = 0;
      var countForm = 0;
      var zipFilename = "Admin_PDF_Form_" + getFormattedTime() + ".zip";

      if (idsList.length > 0 && idsList.length < 49) {
        setLoading(true);
        const res = await apiCaller.post(
          "/api/download-pdf-bulk",
          {
            workflowList: idsList,
          },
          { timeout: 600000 }
        );
        setLoading(false);
        const allData = res.data;
        var urls: any[] = [];
        for (const WF of allData) {
          const workflowId = WF.worflowID;
          const UUIDList = WF.UUIDsListOfStrings;
          for (const [, items] of UUIDList.entries()) {
            let finalKey = workflowId + "_" + items?.uuid;
            var demo = {
              id: finalKey,
              fileLink: items,
            };
            urls.push(demo);
          }
        }

        urls.forEach(function (url, index) {
          var filename = "file-" + url.id + ".pdf";
          // loading a file and add it in a zip file
          JSZipUtils.getBinaryContent(
            typeof url.fileLink === "object" ? url.fileLink?.urlOfPDF : url.fileLink,
            async function (err: any, data: any) {
              if (err) {
                throw err;
              }
              zip.file(filename, data, { binary: true });
              countPDF++;
            }
          );
        });

        let blobList = await getFormData(idsList,setLoading,filteredDataCheckBox);

        if (blobList !== undefined) {
          blobList.forEach(async function (url, index) {
            var filename = `Admin_${url.WFName}_` + getFormattedTime() + `.csv`;
            // loading a file and add it in a zip file
            zip.file(filename, url.blobData, { binary: true });
            countForm++;
          });
        }
        if (blobList !== undefined) {
          if (countPDF === urls.length && countForm === blobList.length) {
            var zipFile = await zip.generateAsync({ type: "blob" });
            saveAs(zipFile, zipFilename);
          }
        }
      } else {
        let blobList = await getFormData(idsList,setLoading,filteredDataCheckBox);

        if (blobList !== undefined) {
          blobList.forEach(async function (url, index) {
            var filename = `Admin_${url.WFName}_` + getFormattedTime() + `.csv`;
            // loading a file and add it in a zip file
            zip.file(filename, url.blobData, { binary: true });
            countForm++;
          });
        }
        if (blobList !== undefined) {
          if (countForm === blobList.length) {
            var zipFileForm = await zip.generateAsync({ type: "blob" });
            saveAs(zipFileForm, zipFilename);
          }
        }
      }
    } catch (e) {
      message.error("Unable to download data!", durationInSeconds);
    }
  };